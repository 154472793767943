import React, { useState, useEffect } from 'react';
import { Box, Link, Button, Stack, Text, IconButton } from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
import Card from "components/card/Card";
import moment from 'moment';

export default function Overview() {
    const [notifications, setNotifications] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [notificationsPerPage] = useState(5); // Adjust as needed

    useEffect(() => {
        const userId = sessionStorage.getItem('userId');
        if (!userId) {
            window.location.href = '/login';
        } else {
            fetchNotifications(userId);
        }
    }, []);

    const fetchNotifications = async (userId) => {
        try {
            const response = await fetch(`https://api.cloudace.co.uk/get-reminders?userId=${userId}`);
            if (response.ok) {
                const data = await response.json();
                if (data && data.length > 0) {
                    setNotifications(data);
                } else {
                    console.error('No notifications data found');
                }
            } else {
                console.error('Failed to fetch notifications data');
            }
        } catch (error) {
            console.error('Error fetching notifications data:', error);
        }
    };

    const deleteNotification = async (id) => {
        try {
            const response = await fetch(`https://api.cloudace.co.uk/delete-reminders/${id}`, {
                method: 'DELETE',
            });
            if (response.ok) {
                setNotifications(notifications.filter(notification => notification.id !== id));
                console.log('Notification deleted successfully');
            } else {
                console.error('Failed to delete notification');
            }
        } catch (error) {
            console.error('Error deleting notification:', error);
        }
    };

    const indexOfLastNotification = currentPage * notificationsPerPage;
    const indexOfFirstNotification = indexOfLastNotification - notificationsPerPage;
    const currentNotifications = notifications.slice(indexOfFirstNotification, indexOfLastNotification);
    const totalPages = Math.ceil(notifications.length / notificationsPerPage);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const formatDate = (timestamp) => {
        const date = new Date(timestamp);
        return moment(date).format('DD-MM-YYYY');
    };

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
            <Link
                color='black'
                href='#'
                bg='inherit'
                borderRadius='inherit'
                fontWeight='bold'
                fontSize='34px'
                _active={{
                    bg: 'inherit',
                    transform: 'none',
                    borderColor: 'transparent'
                }}
                _focus={{
                    boxShadow: 'none'
                }}>
                Role List
            </Link>
            <Card>
                {/* Notification */}
                <Box mt={4}>
                    <Text fontWeight="bold" fontSize="lg">Notifications:</Text>
                    {currentNotifications.length > 0 ? (
                        currentNotifications.map(notification => (
                            <Box key={notification.id} p="4" borderWidth="1px" borderRadius="md" mt="2" position="relative">
                                <Text fontWeight="bold">Employee Name: {notification.eFname} {notification.eLname}</Text>
                                <Text>Date: {formatDate(notification.rDate)}</Text>
                                <Text>Note: {notification.rDescription}</Text>
                                <IconButton
                                    aria-label="Delete notification"
                                    icon={<DeleteIcon />}
                                    position="absolute"
                                    top="1rem"
                                    right="1rem"
                                    onClick={() => deleteNotification(notification.id)}
                                />
                            </Box>
                        ))
                    ) : (
                        <Text fontSize="sm" color="gray.500">
                            No notifications
                        </Text>
                    )}
                </Box>
                {/* Pagination */}
                <Stack direction="row" justify="center" mt={4}>
                    <Button
                        variant="outline"
                        colorScheme="gray"
                        disabled={currentPage === 1}
                        onClick={() => paginate(currentPage - 1)}
                    >
                        Previous
                    </Button>
                    {/* Page numbers */}
                    <Stack direction="row" justify="center" mt={4}>
                        {Array.from({ length: Math.min(totalPages, 5) }, (_, index) => (
                            <Button
                                key={index + 1}
                                variant={currentPage === index + 1 ? "solid" : "outline"}
                                onClick={() => paginate(index + 1)}
                            >
                                {index + 1}
                            </Button>
                        ))}
                    </Stack>
                    <Button
                        variant="outline"
                        colorScheme="gray"
                        disabled={currentPage === totalPages}
                        onClick={() => paginate(currentPage + 1)}
                    >
                        Next
                    </Button>
                </Stack>
            </Card>
        </Box>
    );
}
