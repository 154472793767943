import React, { useState, useEffect } from 'react';
import { Box, Link, Table, Thead, Tbody, Tr, Th, Td, Button, Stack, Menu, MenuItem, MenuList, MenuButton, Input } from "@chakra-ui/react";
import Card from "components/card/Card";
import { NavLink } from 'react-router-dom';
import { MdArrowDropDown } from "react-icons/md";

export default function Overview() {
    const [roles, setRoles] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [rolesPerPage] = useState(10);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        const userId = sessionStorage.getItem('userId');

        if (!userId) {
            window.location.href = '/login';
        } else {
            fetchRoles(userId);
        }
    }, []);

    const fetchRoles = async (userId) => {
        try {
            const response = await fetch(`https://api.cloudace.co.uk/get-role?userId=${userId}`);
            if (response.ok) {
                const data = await response.json();
                setRoles(data);
            } else {
                console.error('Failed to fetch role data');
            }
        } catch (error) {
            console.error('Error fetching role data:', error);
        }
    };

    const indexOfLastRole = currentPage * rolesPerPage;
    const indexOfFirstRole = indexOfLastRole - rolesPerPage;

    const filteredRoles = roles.filter(role =>
        role.rName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        role.rBreakTime.toString().toLowerCase().includes(searchTerm.toLowerCase())
    );

    const currentRoles = filteredRoles.slice(indexOfFirstRole, indexOfLastRole);

    const totalPages = Math.ceil(filteredRoles.length / rolesPerPage);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        setCurrentPage(1); // Reset pagination to first page when searching
    };

    const handleDelete = async (id) => {
        try {
            const response = await fetch(`https://api.cloudace.co.uk/delete-role/${id}`, {
                method: 'DELETE',
            });
            if (response.ok) {
                setRoles(roles.filter(role => role.id !== id));
            } else {
                console.error('Failed to delete role');
            }
        } catch (error) {
            console.error('Error deleting role:', error);
        }
    };

    const goToPreviousPage = () => {
        setCurrentPage(currentPage - 1);
    };

    const goToNextPage = () => {
        setCurrentPage(currentPage + 1);
    };

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
            <Link
                color='black'
                href='#'
                bg='inherit'
                borderRadius='inherit'
                fontWeight='bold'
                fontSize='34px'
                _active={{
                    bg: 'inherit',
                    transform: 'none',
                    borderColor: 'transparent'
                }}
                _focus={{
                    boxShadow: 'none'
                }}>
                Role List
            </Link>
            <Card>
                <Stack spacing={4} direction="row" align="center">
                    <Button variant="brand" size="sm">
                        <NavLink to='role-add' fontSize="sm">Add Role</NavLink>
                    </Button>
                    <Input
                        type="text"
                        placeholder="Search"
                        value={searchTerm}
                        onChange={handleSearchChange}
                    />
                </Stack>
                {/* Main Fields */}
                <Box overflowX="auto">
                    <Table variant="striped" colorScheme="brand">
                        <Thead>
                            <Tr>
                                <Th>SR</Th>
                                <Th>Role Name</Th>
                                <Th>Default Break (Hours)</Th>
                                <Th>Action</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {currentRoles.map((role, index) => (
                                <Tr key={role.id}>
                                    <Td>{indexOfFirstRole + index + 1}</Td>
                                    <Td>{role.rName}</Td>
                                    <Td>{role.rBreakTime}</Td>
                                    <Td>
                                        <Menu>
                                            <MenuButton as={Button} rightIcon={<MdArrowDropDown />}>
                                                Actions
                                            </MenuButton>
                                            <MenuList>
                                                <NavLink to={`role-edit/${role.id}`}><MenuItem>Edit</MenuItem></NavLink>
                                                <MenuItem onClick={() => handleDelete(role.id)}>Delete</MenuItem>
                                            </MenuList>
                                        </Menu>
                                    </Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </Box>
                {/* Pagination */}
                <Stack direction="row" justify="center" mt={4}>
                    <Button
                        variant="outline"
                        colorScheme="gray"
                        disabled={currentPage === 1}
                        onClick={goToPreviousPage}
                    >
                        Previous
                    </Button>
                    {Array.from({ length: totalPages }, (_, index) => (
                        <Button
                            key={index + 1}
                            variant={currentPage === index + 1 ? "solid" : "outline"}
                            onClick={() => paginate(index + 1)}
                        >
                            {index + 1}
                        </Button>
                    ))}
                    <Button
                        variant="outline"
                        colorScheme="gray"
                        disabled={currentPage === totalPages}
                        onClick={goToNextPage}
                    >
                        Next
                    </Button>
                </Stack>
            </Card>
        </Box>
    );
}
