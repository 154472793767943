
import {
    Box,
    Link,
    Grid,
    Form,
    FormControl,
    FormLabel,
    Input,
    Select,
    Textarea,
    FormErrorMessage,
    FormHelperText,
    Button,
} from "@chakra-ui/react";
import Card from "components/card/Card"
import { useHistory } from 'react-router-dom';
import moment from 'moment'; // Import moment for date/time formatting

import React, { useState, useEffect } from 'react';
import { MdArrowDropDown, MdForkRight } from "react-icons/md";
import axios from 'axios';

export default function Overview() {
    const [employees, setEmployees] = useState([]);
    const [locations, setLocations] = useState([]);
    const [formData, setFormData] = useState({
        aEmpId: '',
        aLocId: '',
        aInTime: '',
        aOutTime: '',
        aDefaultBreak: '',
        aInTimeRemark: '',
        aDateFrom: '',
        aDateTo: ''
    });

    useEffect(() => {
        const fetchInitialData = async () => {
            try {
                const userId = sessionStorage.getItem('userId');
                const [employeeResponse, locationResponse] = await Promise.all([
                    axios.get(`https://api.cloudace.co.uk/get-employee?userId=${userId}`),
                    axios.get(`https://api.cloudace.co.uk/get-location?userId=${userId}`)
                ]);
                setEmployees(employeeResponse.data);
                setLocations(locationResponse.data);
            } catch (error) {
                console.error('Error fetching initial data:', error);
            }
        };

        fetchInitialData();
    }, []);

    const history = useHistory();
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const userId = sessionStorage.getItem('userId');
            if (!userId) {
                console.error('User ID not found');
                return;
            }

            const formDataWithUserId = { ...formData, userId: userId };

            // Generate date range
            const dateRange = generateDateRange(formData.aDateFrom, formData.aDateTo);

            // Add rows for each date in the range
            await Promise.all(dateRange.map(date => {
                const dataToAdd = { ...formDataWithUserId, aDate: date };
                return saveFormDataToServer(dataToAdd);
            }));

            history.push('/admin/attendance');
        } catch (error) {
            console.error('Error saving data:', error);
        }
    };

    const generateDateRange = (startDate, endDate) => {
        const dates = [];
        const currentDate = new Date(startDate);
        const end = new Date(endDate);

        while (currentDate <= end) {
            dates.push(currentDate.toISOString().split('T')[0]); // Extract date in 'YYYY-MM-DD' format
            currentDate.setDate(currentDate.getDate() + 1);
        }

        return dates;
    };

    const saveFormDataToServer = async (data) => {
        const response = await fetch('https://api.cloudace.co.uk/add-event', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });

        if (!response.ok) {
            throw new Error('Failed to save data');
        }
    };

    useEffect(() => {
        const userId = sessionStorage.getItem('userId');

        if (userId === null) {
            window.location.href = 'sing-in';
        }
    }, []);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
            <Card>
                <form onSubmit={handleSubmit}>

                    <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                        <FormControl id="employee">
                            <FormLabel>Select Employee:</FormLabel>
                            <Select
                                name="aEmpId"
                                value={formData.aEmpId}
                                onChange={handleChange}
                                required
                            >
                                <option>Select Any</option>
                                {employees.map(employee => (
                                    <option key={employee.id} value={employee.id}>{`${employee.eFname}  ${employee.eLname}`}</option>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl id="location">
                            <FormLabel>Select Location:</FormLabel>
                            <Select
                                name="aLocId"
                                value={formData.aLocId}
                                onChange={handleChange}
                                required
                            >
                                <option>Select Any</option>
                                {locations.map(location => (
                                    <option key={location.id} value={location.id}>{location.lName}</option>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl id="dateFrom">
                            <FormLabel>From Date</FormLabel>
                            <Input
                                type="date"
                                name="aDateFrom"
                                value={formData.aDateFrom}
                                onChange={handleChange} />
                        </FormControl>

                        <FormControl id="dateTo">
                            <FormLabel>To Date</FormLabel>
                            <Input
                                type="date"
                                name="aDateTo"
                                value={formData.aDateTo}
                                onChange={handleChange} />
                        </FormControl>

                        <FormControl id="inTime">
                            <FormLabel>In Time</FormLabel>
                            <Input
                                type="time"
                                name="aInTime"
                                value={formData.aInTime}
                                onChange={handleChange} />
                        </FormControl>

                        <FormControl id="outTime">
                            <FormLabel>Out Time</FormLabel>
                            <Input
                                type="time"
                                name="aOutTime"
                                value={formData.aOutTime}
                                onChange={handleChange} />
                        </FormControl>

                        <FormControl id="defaultBreak">
                            <FormLabel>Default Break</FormLabel>
                            <Input
                                type="number"
                                name="aDefaultBreak"
                                value={formData.aDefaultBreak}
                                onChange={handleChange} />
                        </FormControl>

                        <FormControl id="agenda">
                            <FormLabel>Agenda</FormLabel>
                            <Input
                                type="text"
                                name="aInTimeRemark"
                                value={formData.aInTimeRemark}
                                onChange={handleChange} />
                        </FormControl>
                    </Grid>

                    <Button
                        mt={4}
                        colorScheme="brand"
                        type="submit"
                    >
                        Submit
                    </Button>
                </form>
            </Card>
        </Box>

    );
}
