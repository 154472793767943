import React, { useState, useEffect, } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import {
  Avatar,
  Flex,
  Icon,
  Text,
  useColorModeValue,
  Menu,
  MenuButton,
  MenuList,
  MenuItem
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { FaEthereum } from 'react-icons/fa';
import { MdNotificationsNone } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import { SidebarResponsive } from 'components/sidebar/Sidebar';
import { ItemContent } from 'components/menu/ItemContent';
import { SearchBar } from 'components/navbar/searchBar/SearchBar';
import routes from 'routes.js';
import moment from 'moment';
import Card from 'components/card/Card';

const HeaderLinks = (props) => {
  const history = useHistory();
  const { secondary } = props;
  const navbarIcon = useColorModeValue('gray.400', 'white');
  const menuBg = useColorModeValue('white', 'navy.800');
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const textColorBrand = useColorModeValue('brand.700', 'brand.400');
  const ethColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('#E6ECFA', 'rgba(135, 140, 189, 0.3)');
  const ethBg = useColorModeValue('secondaryGray.300', 'navy.900');
  const ethBox = useColorModeValue('white', 'navy.800');
  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)'
  );

  const [company, setCompany] = useState({ cCompName: 'Loading...' });
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    const fetchCompany = async () => {
      try {
        const userId = sessionStorage.getItem('userId');
        const response = await fetch(`https://api.cloudace.co.uk/get-comp?userId=${userId}`);
        if (response.ok) {
          const data = await response.json();
          if (data && data.length > 0) {
            setCompany(data[0]);
          } else {
            console.error('No company data found');
          }
        } else {
          console.error('Failed to fetch company data');
        }
      } catch (error) {
        console.error('Error fetching company data:', error);
      }
    };

    const fetchNotifications = async () => {
      try {
        const userId = sessionStorage.getItem('userId');
        const response = await fetch(`https://api.cloudace.co.uk/get-reminders?userId=${userId}`);
        if (response.ok) {
          const data = await response.json();
          if (data && data.length > 0) {
            const filteredNotifications = data.filter(notification =>
              moment(notification.rDate).isBefore(moment())
            );
            setNotifications(filteredNotifications);
          } else {
            console.error('No notifications data found');
          }
        } else {
          console.error('Failed to fetch notifications data');
        }
      } catch (error) {
        console.error('Error fetching notifications data:', error);
      }
    };

    fetchCompany();
    fetchNotifications();
  }, []);

  const handleMyProfile = () => {
    const companyId = sessionStorage.getItem('userId');
    history.push(`/admin/profile/${companyId}`);
  };
  const handleMyNotification = () => {
    const companyId = sessionStorage.getItem('userId');
    history.push(`/admin/Notification/`);
  };

  const handleLogout = () => {
    sessionStorage.clear();
    history.push('/auth/sign-in');
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return moment(date).format('DD-MM-YYYY');
  };



  return (
    <Flex
      w={{ sm: '100%', md: 'auto' }}
      alignItems="center"
      flexDirection="row"
      bg={menuBg}
      flexWrap={secondary ? { base: 'wrap', md: 'nowrap' } : 'unset'}
      p="10px"
      borderRadius="30px"
      boxShadow={shadow}
    >

      <Flex
        bg={ethBg}
        display={secondary ? 'flex' : 'none'}
        borderRadius="30px"
        ms="auto"
        p="6px"
        align="center"
        me="6px"
      >
        <Flex align="center" justify="center" bg={ethBox} h="29px" w="29px" borderRadius="30px" me="7px">
          <Icon color={ethColor} w="9px" h="14px" as={FaEthereum} />
        </Flex>
        <Text w="max-content" color={ethColor} fontSize="sm" fontWeight="700" me="6px">
          1,924
          <Text as="span" display={{ base: 'none', md: 'unset' }}>
            {' '}
            ETH
          </Text>
        </Text>
      </Flex>
      <SidebarResponsive routes={routes} />
      <Menu>
       

      </Menu>

      <Menu>
        <MenuButton p="0px">
          <Avatar
            _hover={{ cursor: 'pointer' }}
            color="white"
            name={company.cCompName}
            bg="#11047A"
            size="sm"
            w="40px"
            h="40px"
          />
        </MenuButton>
        <MenuList boxShadow={shadow} p="0px" mt="10px" borderRadius="20px" bg={menuBg} border="none">
          <Flex w="100%" mb="0px">
            <Text
              ps="20px"
              pt="16px"
              pb="10px"
              w="100%"
              borderBottom="1px solid"
              borderColor={borderColor}
              fontSize="sm"
              fontWeight="700"
              color={textColor}
            >
              👋&nbsp; Hey, <label>{company.cCompName}</label>
            </Text>
          </Flex>
          <Flex flexDirection="column" p="10px">
            <MenuItem onClick={handleMyProfile} _hover={{ bg: 'none' }} _focus={{ bg: 'none' }} borderRadius="8px" px="14px" >
              Profile Settings
            </MenuItem>
            <MenuItem onClick={handleMyNotification} _hover={{ bg: 'none' }} _focus={{ bg: 'none' }} borderRadius="8px" px="14px" >
              Notifications
            </MenuItem>
            <MenuItem onClick={handleLogout} _hover={{ bg: 'none' }} _focus={{ bg: 'none' }} color="red.400" borderRadius="8px" px="14px">
              <Text fontSize="sm">Log out</Text>
            </MenuItem>
          </Flex>
        </MenuList>
      </Menu>
    </Flex>
  );
};

HeaderLinks.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func
};

export default HeaderLinks;
