import {
    Box,
    Grid,
    FormControl,
    FormLabel,
    Input,
    Select,
    Button,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import axios from 'axios';

export default function Overview() {
    const { id } = useParams();
    const history = useHistory();

    const [employees, setEmployees] = useState([]);
    const [locations, setLocations] = useState([]);
    const [formData, setFormData] = useState({
        aEmpId: '',
        aLocId: '',
        aInTime: '',
        aOutTime: '',
        aDefaultBreak: '',
        aInTimeRemark: ''
    });

    useEffect(() => {
        const fetchInitialData = async () => {
            try {
                const userId = sessionStorage.getItem('userId');
                const [employeeResponse, locationResponse] = await Promise.all([
                    axios.get(`https://api.cloudace.co.uk/get-employee?userId=${userId}`),
                    axios.get(`https://api.cloudace.co.uk/get-location?userId=${userId}`)
                ]);
                setEmployees(employeeResponse.data);
                setLocations(locationResponse.data);
            } catch (error) {
                console.error('Error fetching initial data:', error);
            }
        };

        fetchInitialData();
    }, []);

    useEffect(() => {
        const fetchAttendanceDetails = async () => {
            try {
                const response = await axios.get(`https://api.cloudace.co.uk/fetch-attendance/${id}`);
                const attendanceDetails = response.data;

                setFormData({
                    ...attendanceDetails,
                    aEmpId: attendanceDetails.aEmpId.toString(),
                    aLocId: attendanceDetails.aLocId.toString(),
                    // Format time values for input fields (HH:mm)
                    aInTime: moment(attendanceDetails.aInTime, 'HH:mm:ss').format('HH:mm'),
                    aOutTime: moment(attendanceDetails.aOutTime, 'HH:mm:ss').format('HH:mm')
                });
            } catch (error) {
                console.error('Error fetching attendance details:', error);
            }
        };

        fetchAttendanceDetails();
    }, [id]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await updateAttendance(formData);
            history.push('/admin/attendance');
        } catch (error) {
            console.error('Error saving data:', error);
            // Handle error state or display an error message to the user
        }
    };

    const updateAttendance = async (formData) => {
        try {
            const response = await axios.put(`https://api.cloudace.co.uk/update-attendance/${id}`, formData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (!response.data.success) {
                throw new Error('Failed to update attendance');
            }
        } catch (error) {
            console.error('Error updating attendance:', error);
            throw new Error('Failed to update attendance');
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        
        // Handle specific formatting for time and number inputs
        if (name === 'aInTime' || name === 'aOutTime') {
            // Format time value to HH:mm
            const formattedTime = moment(value, 'HH:mm').format('HH:mm:ss');
            setFormData({ ...formData, [name]: formattedTime });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    return (
        <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
            <Card>
                <form onSubmit={handleSubmit}>
                    <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                        <FormControl id="employee">
                            <FormLabel>Select Employee:</FormLabel>
                            <Select
                                name="aEmpId"
                                value={formData.aEmpId}
                                onChange={handleChange}
                                required
                            >
                                {employees.map(employee => (
                                    <option key={employee.id} value={employee.id}>
                                        {`${employee.eFname} ${employee.eLname}`}
                                    </option>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl id="location">
                            <FormLabel>Select Location:</FormLabel>
                            <Select
                                name="aLocId"
                                value={formData.aLocId}
                                onChange={handleChange}
                                required
                            >
                                {locations.map(location => (
                                    <option key={location.id} value={location.id}>
                                        {location.lName}
                                    </option>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl id="inTime">
                            <FormLabel>In Time</FormLabel>
                            <Input
                                type="time"
                                name="aInTime"
                                value={formData.aInTime}
                                onChange={handleChange}
                                required
                            />
                        </FormControl>
                        <FormControl id="outTime">
                            <FormLabel>Out Time</FormLabel>
                            <Input
                                type="time"
                                name="aOutTime"
                                value={formData.aOutTime}
                                onChange={handleChange}
                                required
                            />
                        </FormControl>
                        <FormControl id="defaultBreak">
                            <FormLabel>Default Break</FormLabel>
                            <Input
                                type="number"
                                name="aDefaultBreak"
                                value={formData.aDefaultBreak}
                                onChange={handleChange}
                                required
                            />
                        </FormControl>
                        <FormControl id="agenda">
                            <FormLabel>Agenda</FormLabel>
                            <Input
                                type="text"
                                name="aInTimeRemark"
                                value={formData.aInTimeRemark}
                                onChange={handleChange}
                            />
                        </FormControl>
                    </Grid>
                    <Button mt={4} colorScheme="brand" type="submit">
                        Submit
                    </Button>
                </form>
            </Card>
        </Box>
    );
}
