import React, { useState, useEffect } from 'react';
import { Box, Link, Table, Thead, Tbody, Tr, Th, Td, Input, Button, Stack, Menu, MenuItem, MenuList, Badge, MenuButton } from "@chakra-ui/react";
import Card from "components/card/Card";
import { NavLink } from 'react-router-dom';
import moment from 'moment';
import { MdArrowDropDown } from "react-icons/md";

export default function Overview() {
    const [leave, setLeave] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [leavesPerPage] = useState(10);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        const userId = sessionStorage.getItem('userId');

        if (!userId) {
            window.location.href = '/login';
        } else {
            fetchLeaveData(userId);
        }
    }, []);

    const fetchLeaveData = async (userId) => {
        try {
            const response = await fetch(`https://api.cloudace.co.uk/get-leave?userId=${userId}`);
            if (response.ok) {
                const data = await response.json();
                setLeave(data);
            } else {
                console.error('Failed to fetch leave data');
            }
        } catch (error) {
            console.error('Error fetching leave data:', error);
        }
    };

    const indexOfLastLeave = currentPage * leavesPerPage;
    const indexOfFirstLeave = indexOfLastLeave - leavesPerPage;

    const filteredLeave = leave.filter(item =>
        (item.eFname && item.eFname.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (item.eLname && item.eLname.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (item.lType && item.lType.toLowerCase().includes(searchTerm.toLowerCase()))
    );

    const currentLeave = filteredLeave.slice(indexOfFirstLeave, indexOfLastLeave);

    const totalPages = Math.ceil(filteredLeave.length / leavesPerPage);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const handleApprove = async (id) => {
        try {
            const response = await fetch(`https://api.cloudace.co.uk/update-leave/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ lStatus: 1 }) // Set lStatus to 1 (Accepted)
            });
            if (response.ok) {
                setLeave(leave.map(item => (item.id === id ? { ...item, lStatus: 1 } : item)));
            } else {
                console.error('Failed to update leave status');
            }
        } catch (error) {
            console.error('Error updating leave status:', error);
        }
    };

    const handleReject = async (id) => {
        try {
            const response = await fetch(`https://api.cloudace.co.uk/update-leave/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ lStatus: 2 }) // Set lStatus to 2 (Rejected)
            });
            if (response.ok) {
                setLeave(leave.map(item => (item.id === id ? { ...item, lStatus: 2 } : item)));
            } else {
                console.error('Failed to update leave status');
            }
        } catch (error) {
            console.error('Error updating leave status:', error);
        }
    };

    const handleDelete = async (id) => {
        try {
            const response = await fetch(`https://api.cloudace.co.uk/delete-leave/${id}`, {
                method: 'DELETE'
            });
            if (response.ok) {
                setLeave(leave.filter(item => item.id !== id));
            } else {
                console.error('Failed to delete leave record');
            }
        } catch (error) {
            console.error('Error deleting leave record:', error);
        }
    };

    const renderStatusBadge = (lStatus) => {
        let statusText;
        let badgeColor;

        switch (lStatus) {
            case 0:
                statusText = 'Action Pending';
                badgeColor = 'gray';
                break;
            case 1:
                statusText = 'Accepted';
                badgeColor = 'green';
                break;
            case 2:
                statusText = 'Rejected';
                badgeColor = 'red';
                break;
            default:
                statusText = '';
                badgeColor = '';
        }

        return <Badge variant="solid" colorScheme={badgeColor}>{statusText}</Badge>;
    };

    const formatDate = (timestamp) => {
        const date = new Date(timestamp);
        return moment(date).format('DD-MM-YYYY');
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        setCurrentPage(1); // Reset pagination to first page when searching
    };

    const goToPreviousPage = () => {
        setCurrentPage(currentPage - 1);
    };

    const goToNextPage = () => {
        setCurrentPage(currentPage + 1);
    };

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
            <Link
                color='black'
                href='#'
                bg='inherit'
                borderRadius='inherit'
                fontWeight='bold'
                fontSize='34px'
                _active={{
                    bg: 'inherit',
                    transform: 'none',
                    borderColor: 'transparent'
                }}
                _focus={{
                    boxShadow: 'none'
                }}>
                Leave List
            </Link>
            <Card>
                <Stack spacing={4} direction="row" align="center">
                    <Button variant="brand" size="sm">
                        <NavLink to='leave-add' fontSize="sm">Add Leave</NavLink>
                    </Button>
                    <Input
                        type="text"
                        placeholder="Search by Employee Name or Leave Type"
                        value={searchTerm}
                        onChange={handleSearchChange}
                    />
                </Stack>
                {/* Main Fields */}
                <Box overflowX="auto">
                    <Table variant="striped" colorScheme="brand">
                        <Thead>
                            <Tr>
                                <Th>SR</Th>
                                <Th>EMPLOYEE NAME</Th>
                                <Th>LEAVE TYPE</Th>
                                <Th>FROM DATE</Th>
                                <Th>TO DATE</Th>
                                <Th>REASON</Th>
                                <Th>STATUS</Th>
                                <Th>Action</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {currentLeave.map((item, index) => (
                                <Tr key={item.id}>
                                    <Td>{indexOfFirstLeave + index + 1}</Td>
                                    <Td>{`${item.eFname} ${item.eLname}`}</Td>
                                    <Td>{item.lType}</Td>
                                    <Td>{formatDate(item.lFromDate)}</Td>
                                    <Td>{formatDate(item.lToDate)}</Td>
                                    <Td>{item.lReason}</Td>
                                    <Td>{renderStatusBadge(item.lStatus)}</Td>
                                    <Td>
                                        <Menu>
                                            <MenuButton as={Button} rightIcon={<MdArrowDropDown />} variant="ghost">
                                                Actions
                                            </MenuButton>
                                            <MenuList>
                                                <MenuItem onClick={() => handleApprove(item.id)}>Approve</MenuItem>
                                                <MenuItem onClick={() => handleReject(item.id)}>Reject</MenuItem>
                                                <MenuItem onClick={() => handleDelete(item.id)}>Delete</MenuItem>
                                            </MenuList>
                                        </Menu>
                                    </Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </Box>
                {/* Pagination */}
                <Stack direction="row" justify="center" mt={4}>
                    <Button
                        variant="outline"
                        colorScheme="gray"
                        disabled={currentPage === 1}
                        onClick={goToPreviousPage}
                    >
                        Previous
                    </Button>
                    {Array.from({ length: totalPages }, (_, index) => (
                        <Button
                            key={index + 1}
                            variant="outline"
                            colorScheme={currentPage === index + 1 ? "blue" : "gray"}
                            onClick={() => paginate(index + 1)}
                        >
                            {index + 1}
                        </Button>
                    ))}
                    <Button
                        variant="outline"
                        colorScheme="gray"
                        disabled={currentPage === totalPages}
                        onClick={goToNextPage}
                    >
                        Next
                    </Button>
                </Stack>
            </Card>
        </Box>
    );
}
