
import {
    Box,
    Grid,
    FormControl,
    FormLabel,
    Input,
    Select,
    Button,
} from "@chakra-ui/react";
import Card from "components/card/Card"
import { useHistory } from 'react-router-dom';
import moment from 'moment'; // Import moment for date/time formatting

import React, { useState, useEffect } from 'react';
import { MdArrowDropDown, MdForkRight } from "react-icons/md";
import axios from 'axios';

export default function Overview() {
    const [formData, setFormData] = useState({
        rName: '',
        rBreakTIme: '',
        rReportsTo: '',
        cCompEmail: ''
    });

    const [employee, setLocations] = useState([]);
    const [leavetype, setLeaveType] = useState([]);
    const history = useHistory();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {

            const userId = sessionStorage.getItem('userId');
            if (!userId) {
                console.error('User ID not found');
                return;
            }

            const formDataWithUserId = { ...formData, userId: userId };
            await saveFormDataToServer(formDataWithUserId);
            history.push('/admin/leave');
        } catch (error) {
            console.error('Error saving data:', error);
        }
    };

    const saveFormDataToServer = async (data) => {
        const response = await fetch('https://api.cloudace.co.uk/add-leave', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });

        if (!response.ok) {
            throw new Error('Failed to save data');
        }
    };

    useEffect(() => {
        const userId = sessionStorage.getItem('userId');

        if (userId === null) {
            window.location.href = '/login';
        }

        const fetchLocations = async () => {
            try {
                const response = await axios.get(`https://api.cloudace.co.uk/get-employee?userId=${userId}`);
                setLocations(response.data);
            } catch (error) {
                console.error('Error fetching locations:', error);
            }
        };

        const fetchType = async () => {
            try {
                const response = await axios.get('https://api.cloudace.co.uk/get-leavetype');
                setLeaveType(response.data);
            } catch (error) {
                console.error('Error fetching leavetype:', error);
            }
        };


        fetchLocations();
        fetchType();
    }, []);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
            <Card>
                <form onSubmit={handleSubmit}>

                    <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                        <FormControl id="employee">
                            <FormLabel>Select Employee:</FormLabel>
                            <Select
                                name="lEmpId"
                                value={formData.lEmpId}
                                onChange={handleChange}
                                required
                                className='form-control'
                            >
                                <option>Select Any</option>
                                {employee.map(employee => (
                                    <option key={employee.id} value={employee.id}>{employee.eFname} {employee.eMname} {employee.eLname}</option>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl id="location">
                            <FormLabel>Select Leave Type</FormLabel>
                            <Select
                                name="lType"
                                value={formData.lType}
                                onChange={handleChange}
                                required
                                className='form-control'
                            >
                                <option>Select Any</option>
                                {leavetype.map(leavetype => (
                                    <option key={leavetype.id} value={leavetype.id}>{leavetype.lType} </option>
                                ))}
                            </Select>
                        </FormControl>



                        <FormControl id="dateTo">
                            <FormLabel>From Date</FormLabel>
                            <Input
                                type="date"
                                name="lFromDate"
                                value={formData.lFromDate}
                                onChange={handleChange}
                                required
                                className='form-control'
                            />
                        </FormControl>

                        <FormControl id="dateTo">
                            <FormLabel>To Date</FormLabel>
                            <Input
                                type="date"
                                name="lToDate"
                                value={formData.lToDate}
                                onChange={handleChange}
                                required
                                className='form-control'
                            />
                        </FormControl>

                        <FormControl id="dateTo">
                            <FormLabel>Reason</FormLabel>
                            <Input
                                type="text"
                                name="lReason"
                                value={formData.lReason}
                                onChange={handleChange}
                                required
                                className='form-control'
                            />
                        </FormControl>
                    </Grid>

                    <Button
                        mt={4}
                        colorScheme="brand"
                        type="submit"
                    >
                        Submit
                    </Button>
                </form>
            </Card>
        </Box>

    );
}
