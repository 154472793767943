import React, { useState, useEffect } from 'react';
import { Box, Link, Table, Thead, Tbody, Tr, Th, Td, Input, Button, Stack, Menu, MenuItem, MenuList, Badge, MenuButton } from "@chakra-ui/react";
import Card from "components/card/Card";
import { NavLink } from 'react-router-dom';
import moment from 'moment';
import { MdArrowDropDown } from "react-icons/md";

export default function Overview() {
    const [employees, setEmployees] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [employeesPerPage] = useState(10);
    const [searchTerm, setSearchTerm] = useState('');
    const [userId, setUserId] = useState(null);

    useEffect(() => {
        const userId = sessionStorage.getItem('userId');

        if (!userId) {
            window.location.href = '/login';
        } else {
            setUserId(userId);
            fetchEmployees(userId);
        }
    }, []);

    const fetchEmployees = async (userId) => {
        try {
            const response = await fetch(`https://api.cloudace.co.uk/get-employee?userId=${userId}`);
            if (response.ok) {
                const data = await response.json();
                setEmployees(data);
            } else {
                console.error('Failed to fetch employee data');
            }
        } catch (error) {
            console.error('Error fetching employee data:', error);
        }
    };

    const indexOfLastEmployee = currentPage * employeesPerPage;
    const indexOfFirstEmployee = indexOfLastEmployee - employeesPerPage;
    const filteredEmployees = employees.filter(emp =>
        emp && (
            emp.eFname.toLowerCase().includes(searchTerm.toLowerCase()) ||
            emp.eLname.toLowerCase().includes(searchTerm.toLowerCase()) ||
            emp.eEmail.toLowerCase().includes(searchTerm.toLowerCase())
        )
    );
    const currentEmployees = filteredEmployees.slice(indexOfFirstEmployee, indexOfLastEmployee);

    const totalPages = Math.ceil(filteredEmployees.length / employeesPerPage);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        setCurrentPage(1); // Reset pagination to first page when searching
    };

    const handleDelete = async (id) => {
        try {
            const response = await fetch(`https://api.cloudace.co.uk/delete-employee/${id}`, {
                method: 'DELETE',
            });
            if (response.ok) {
                setEmployees(employees.filter(emp => emp.id !== id));
                // Optionally, you may show a success message or perform other actions after deletion
            } else {
                console.error('Failed to delete employee');
            }
        } catch (error) {
            console.error('Error deleting employee:', error);
        }
    };

    const formatDate = (timestamp) => {
        const date = new Date(timestamp);
        return moment(date).format('DD-MM-YYYY');
    };

    const goToPreviousPage = () => {
        setCurrentPage(currentPage - 1);
    };

    const goToNextPage = () => {
        setCurrentPage(currentPage + 1);
    };

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
            <Link
                color='black'
                href='#'
                bg='inherit'
                borderRadius='inherit'
                fontWeight='bold'
                fontSize='34px'
                _active={{
                    bg: 'inherit',
                    transform: 'none',
                    borderColor: 'transparent'
                }}
                _focus={{
                    boxShadow: 'none'
                }}>
                Employee List
            </Link>
            <Card>
                <Stack spacing={4} direction="row" align="center">
                    <Button variant="brand" size="sm">
                        <NavLink to='employee-add' fontSize="sm">Add Employee</NavLink>
                    </Button>
                    <Input
                        type="text"
                        placeholder="Search by Name or Email"
                        value={searchTerm}
                        onChange={handleSearchChange}
                    />
                </Stack>
                {/* Main Fields */}
                <Box overflowX="auto">
                    <Table variant="striped" colorScheme="brand">
                        <Thead>
                            <Tr>
                                <Th>SR</Th>
                                <Th>NAME</Th>
                                <Th>EMAIL</Th>
                                <Th>ROLE</Th>
                                <Th>DOJ</Th>
                                <Th>WAGE / SALARY</Th>
                                <Th>Contact No</Th>
                                <Th>Action</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {currentEmployees.map((emp, index) => (
                                <Tr key={emp.id}>
                                    <Td>{indexOfFirstEmployee + index + 1}</Td>
                                    <Td>{emp.eFname} {emp.eLname}</Td>
                                    <Td>{emp.eEmail}</Td>
                                    <Td>{emp.rName}</Td>
                                    <Td>{formatDate(emp.eDOJ)}</Td>
                                    <Td>£{emp.eBaiscPay || 'N/A'}</Td>
                                    <Td>{emp.eMobNo}</Td>
                                    <Td>
                                        <Menu>
                                            <MenuButton as={Button} rightIcon={<MdArrowDropDown />} variant="ghost">
                                                Actions
                                            </MenuButton>
                                            <MenuList>
                                                <NavLink to={`employee-view/${emp.id}`}><MenuItem>Edit</MenuItem></NavLink>
                                                <MenuItem onClick={() => handleDelete(emp.id)}>Delete</MenuItem>
                                            </MenuList>
                                        </Menu>
                                    </Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </Box>
                {/* Pagination */}
                <Stack direction="row" justify="center" mt={4}>
                    <Button
                        variant="outline"
                        colorScheme="gray"
                        disabled={currentPage === 1}
                        onClick={goToPreviousPage}
                    >
                        Previous
                    </Button>
                    {Array.from({ length: totalPages }, (_, index) => (
                        <Button
                            key={index + 1}
                            variant="outline"
                            colorScheme={currentPage === index + 1 ? "blue" : "gray"}
                            onClick={() => paginate(index + 1)}
                        >
                            {index + 1}
                        </Button>
                    ))}
                    <Button
                        variant="outline"
                        colorScheme="gray"
                        disabled={currentPage === totalPages}
                        onClick={goToNextPage}
                    >
                        Next
                    </Button>
                </Stack>
            </Card>
        </Box>
    );
}
