import React, { useState, useEffect } from 'react';
import { Box, Grid, FormControl, FormLabel, Input, Select, Button } from "@chakra-ui/react";
import Card from "components/card/Card";
import { useHistory, useParams } from 'react-router-dom';
import axios from 'axios';

export default function Overview() {
    const [roleList, setRoleList] = useState([]);
    const [formData, setFormData] = useState({
        rName: '',
        rBreakTIme: '', // Ensure the property name matches the API response
        rReportsTo: ''
    });

    const { id } = useParams();
    const history = useHistory();

    useEffect(() => {
        const fetchRoleData = async () => {
            try {
                const response = await axios.get(`https://api.cloudace.co.uk/get-role/${id}`);
                if (response.data) {
                    setFormData(response.data);
                } else {
                    console.error('Failed to fetch role data');
                }
            } catch (error) {
                console.error('Error fetching role data:', error);
            }
        };

        const fetchRoles = async () => {
            try {
                const userId = sessionStorage.getItem('userId');
                const response = await axios.get(`https://api.cloudace.co.uk/get-role?userId=${userId}`);
                if (response.data) {
                    setRoleList(response.data);
                } else {
                    console.error('Failed to fetch role list');
                }
            } catch (error) {
                console.error('Error fetching role list:', error);
            }
        };

        fetchRoleData();
        fetchRoles();
    }, [id]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await updateRole(formData);
            history.push('/admin/role');
        } catch (error) {
            console.error('Error updating role:', error);
        }
    };

    const updateRole = async (data) => {
        try {
            await axios.put(`https://api.cloudace.co.uk/edit-role/${id}`, data);
        } catch (error) {
            console.error('Error updating role:', error);
            throw new Error('Failed to update role');
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    return (
        <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
            <Card>
                <form onSubmit={handleSubmit}>
                    <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                        <FormControl id="roleName">
                            <FormLabel>Role Name</FormLabel>
                            <Input
                                type="text"
                                name="rName"
                                value={formData.rName}
                                onChange={handleChange}
                                required
                            />
                        </FormControl>
                        <FormControl id="breakTime">
                            <FormLabel>Default Break (In Hours)</FormLabel>
                            <Input
                                type="number"
                                name="rBreakTIme"
                                value={formData.rBreakTIme} // Ensure correct property name
                                onChange={handleChange}
                                required
                            />
                        </FormControl>
                        <FormControl id="reportsTo">
                            <FormLabel>Reports To</FormLabel>
                            <Select
                                name="rReportsTo"
                                value={formData.rReportsTo}
                                onChange={handleChange}
                                required
                            >
                                {roleList.map(role => (
                                    <option key={role.id} value={role.id}>{role.rName}</option>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Button mt={4} colorScheme="brand" type="submit">
                        Submit
                    </Button>
                </form>
            </Card>
        </Box>
    );
}
