import React, { useState, useEffect } from 'react';
import {
    Box,
    Link,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Button,
    Stack,
    Input,
    useBreakpointValue
} from "@chakra-ui/react";
import Card from "components/card/Card";
import moment from 'moment';

export default function LeaveReport() {
    const [leave, setLeave] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [locationsPerPage] = useState(10);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        const userId = sessionStorage.getItem('userId');
        if (!userId) {
            window.location.href = '/login';
        } else {
            fetchLeaveData(userId);
        }
    }, []);

    const fetchLeaveData = async (userId) => {
        try {
            const response = await fetch(`https://api.cloudace.co.uk/report-leave?userId=${userId}`);
            if (response.ok) {
                const data = await response.json();
                setLeave(data);
            } else {
                console.error('Failed to fetch leave data');
            }
        } catch (error) {
            console.error('Error fetching leave data:', error);
        }
    };

    const totalPages = Math.ceil(leave.length / locationsPerPage);

    const indexOfLastLeave = currentPage * locationsPerPage;
    const indexOfFirstLeave = indexOfLastLeave - locationsPerPage;

    const filteredLeave = leave.filter(item =>
        item.eFname && item.eFname.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const currentLeave = filteredLeave.slice(indexOfFirstLeave, indexOfLastLeave);

    const paginate = (pageNumber) => {
        if (pageNumber < 1 || pageNumber > totalPages) return;
        setCurrentPage(pageNumber);
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        setCurrentPage(1); // Reset pagination to first page when searching
    };

    const formatDate = (timestamp) => moment(timestamp).format('DD-MM-YYYY');

    const exportToCSV = () => {
        const csvContent = [
            ['Employee Name', 'Leave Type', 'From Date', 'To Date', 'Reason'],
            ...filteredLeave.map(item => [
                `${item.eFname} ${item.eLname}`,
                item.lType,
                formatDate(item.lFromDate),
                formatDate(item.lToDate),
                item.lReason
            ])
        ];

        const csvRows = csvContent.map(row => row.join(',')).join('\n');
        const csvBlob = new Blob([csvRows], { type: 'text/csv;charset=utf-8' });
        const csvUrl = URL.createObjectURL(csvBlob);

        const tempLink = document.createElement('a');
        tempLink.href = csvUrl;
        tempLink.setAttribute('download', 'leave_report.csv');
        tempLink.click();

        URL.revokeObjectURL(csvUrl);
    };

    const inputWidth = useBreakpointValue({ base: '100%', md: 'auto' });

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
            <Link
                color='black'
                bg='inherit'
                borderRadius='inherit'
                fontWeight='bold'
                fontSize={{ base: "24px", md: "34px" }}
                _active={{
                    bg: 'inherit',
                    transform: 'none',
                    borderColor: 'transparent'
                }}
                _focus={{
                    boxShadow: 'none'
                }}>
                Leave Report
            </Link>
            <Card>
                <Stack spacing={4} direction="row" align="center">
                    <Button variant="brand" size="sm" onClick={exportToCSV}>
                        Export CSV
                    </Button>
                    <Input
                        type="text"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        placeholder="Search by name"
                        maxW={inputWidth}
                    />
                </Stack>
                <Box overflowX="auto">
                    <Table variant="striped" colorScheme="brand" mt={4} minW="100%">
                        <Thead>
                            <Tr>
                                <Th>SR</Th>
                                <Th>Employee Name</Th>
                                <Th>Leave Type</Th>
                                <Th>From Date</Th>
                                <Th>To Date</Th>
                                <Th>Reason</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {currentLeave.map((item, index) => (
                                <Tr key={index}>
                                    <Td>{indexOfFirstLeave + index + 1}</Td>
                                    <Td>{`${item.eFname} ${item.eLname}`}</Td>
                                    <Td>{item.lType}</Td>
                                    <Td>{formatDate(item.lFromDate)}</Td>
                                    <Td>{formatDate(item.lToDate)}</Td>
                                    <Td>{item.lReason}</Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </Box>
                <Stack direction="row" justify="center" mt={4}>
                    <Button onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}>
                        Previous
                    </Button>
                    {Array.from({ length: totalPages }, (_, index) => (
                        <Button
                            key={index + 1}
                            variant={currentPage === index + 1 ? "solid" : "outline"}
                            onClick={() => paginate(index + 1)}
                        >
                            {index + 1}
                        </Button>
                    ))}
                    <Button onClick={() => paginate(currentPage + 1)} disabled={currentPage === totalPages}>
                        Next
                    </Button>
                </Stack>
            </Card>
        </Box>
    );
}
