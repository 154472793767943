import React, { useState, useEffect } from 'react';
import {
    Box,
    Link,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Badge,
    Stack,
    FormControl,
    FormLabel,
    Select,
    Button
} from "@chakra-ui/react";
import Card from "components/card/Card";
import moment from 'moment';
import axios from 'axios';

export default function Overview() {
    const [companies, setCompanies] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [companiesPerPage] = useState(10);
    const [searchTerm, setSearchTerm] = useState('');
    const [employees, setEmployees] = useState([]);
    const [selectedEmployeeId, setSelectedEmployeeId] = useState('');
    const [selectedMonth, setSelectedMonth] = useState('');
    const [selectedYear, setSelectedYear] = useState('');

    useEffect(() => {
        const userId = sessionStorage.getItem('userId');
        if (!userId) {
            window.location.href = '/login';
        } else {
            fetchCompanies(userId);
            fetchEmployees(userId);
        }
    }, []);

    const fetchCompanies = async (userId) => {
        try {
            const response = await axios.get(`https://api.cloudace.co.uk/get-atds-report?userId=${userId}`);
            if (response.status === 200) {
                setCompanies(response.data);
            } else {
                console.error('Failed to fetch company data');
            }
        } catch (error) {
            console.error('Error fetching company data:', error);
        }
    };

    const fetchEmployees = async (userId) => {
        try {
            const response = await axios.get(`https://api.cloudace.co.uk/get-employee?userId=${userId}`);
            if (response.status === 200) {
                setEmployees(response.data);
            } else {
                console.error('Failed to fetch employees');
            }
        } catch (error) {
            console.error('Error fetching employees:', error);
        }
    };

    const fetchFilteredData = async () => {
        try {
            const response = await axios.get(`https://api.cloudace.co.uk/get-atds-report`, {
                params: {
                    userId: sessionStorage.getItem('userId'),
                    employeeId: selectedEmployeeId,
                    month: selectedMonth,
                    year: selectedYear
                }
            });

            if (response.status === 200) {
                setCompanies(response.data);
            } else {
                console.error('Failed to fetch filtered data');
            }
        } catch (error) {
            console.error('Error fetching filtered data:', error);
        }
    };

    const handleFilterClick = () => {
        setCurrentPage(1);
        fetchFilteredData();
    };

    const handleExportCSV = () => {
        const header = [
            'Name',
            'Location',
            'Date',
            'In-Time',
            'Out-Time',
            'Note',
            'Status'
        ];

        const csvData = companies.map(company => [
            `${company.eFname} ${company.eLname}`,
            company.lName,
            moment(company.aDate).format('YYYY-MM-DD'),
            company.aInTime,
            company.aOutTime,
            company.aInTimeRemark,
            company.aStatus === 1 ? 'Present' : 'Absent'
        ]);

        const csvRows = [header, ...csvData];
        const csvContent = csvRows.map(row => row.join(',')).join('\n');
        const blob = new Blob([csvContent], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('href', url);
        a.setAttribute('download', 'attendance_report.csv');
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    const indexOfLastCompany = currentPage * companiesPerPage;
    const indexOfFirstCompany = indexOfLastCompany - companiesPerPage;
    const currentCompanies = companies.slice(indexOfFirstCompany, indexOfLastCompany);

    const totalPages = Math.ceil(companies.length / companiesPerPage);

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const formatDate = (timestamp) => moment(timestamp).format('DD-MM-YYYY');
    const formatTime = (time) => moment(time, 'HH:mm:ss').format('hh:mm A');

    const yearOptions = () => {
        const currentYear = moment().year();
        return [
            currentYear - 1, // Previous year
            currentYear,     // Current year
            currentYear + 1  // Next year
        ];
    };

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
            <Link
                as="h1"
                fontSize={{ base: "2xl", md: "3xl", xl: "4xl" }}
                fontWeight="bold"
                color="black"
                bg="inherit"
                borderRadius="inherit"
                _active={{
                    bg: 'inherit',
                    transform: 'none',
                    borderColor: 'transparent'
                }}
                _focus={{
                    boxShadow: 'none'
                }}
            >
                Attendance Report
            </Link>
            <Card>
                <Stack spacing={4} direction={{ base: "column", md: "row" }} align="center">
                    <FormControl>
                        <FormLabel>Select Employee:</FormLabel>
                        <Select
                            value={selectedEmployeeId}
                            onChange={(e) => setSelectedEmployeeId(e.target.value)}
                        >
                            <option value="">Select Any</option>
                            {employees.map(employee => (
                                <option key={employee.id} value={employee.id}>{`${employee.eFname} ${employee.eLname}`}</option>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl>
                        <FormLabel>Select Month:</FormLabel>
                        <Select
                            value={selectedMonth}
                            onChange={(e) => setSelectedMonth(e.target.value)}
                        >
                            <option value="">Select Any</option>
                            {moment.months().map((month, index) => (
                                <option key={index + 1} value={index + 1}>{month}</option>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl>
                        <FormLabel>Select Year:</FormLabel>
                        <Select
                            value={selectedYear}
                            onChange={(e) => setSelectedYear(e.target.value)}
                        >
                            <option value="">Select Any</option>
                            {yearOptions().map(year => (
                                <option key={year} value={year}>{year}</option>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl>
                        <br />
                        <Button variant="brand" size="lg" onClick={handleFilterClick}>Filter</Button>
                    </FormControl>
                    <FormControl>
                        <br />
                        <Button variant="brand" size="lg" onClick={handleExportCSV}>Export</Button>
                    </FormControl>
                </Stack>
                {/* Main Fields */}
                <Box overflowX="auto">
                    <Table variant="striped" colorScheme="brand" size="sm">
                        <Thead>
                            <Tr>
                                <Th>SR</Th>
                                <Th>Name</Th>
                                <Th>Location</Th>
                                <Th>Date</Th>
                                <Th>In-Time</Th>
                                <Th>Out-Time</Th>
                                <Th>Note</Th>
                                <Th>Status</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {currentCompanies.map((company, index) => (
                                <Tr key={company.id}>
                                    <Td>{indexOfFirstCompany + index + 1}</Td>
                                    <Td>{`${company.eFname} ${company.eLname}`}</Td>
                                    <Td>{company.lName}</Td>
                                    <Td>{formatDate(company.aDate)}</Td>
                                    <Td>{formatTime(company.aInTime)}</Td>
                                    <Td>{formatTime(company.aOutTime)}</Td>
                                    <Td>{company.aInTimeRemark}</Td>
                                    <Td>
                                        {company.aLeaveStatus > 0 ? (
                                            <Badge variant="solid" colorScheme="yellow">On Leave</Badge>
                                        ) : company.aStatus === 1 ? (
                                            <Badge variant="solid" colorScheme="green">Present</Badge>
                                        ) : (
                                            <Badge variant="solid" colorScheme="red">Absent</Badge>
                                        )}
                                    </Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </Box>
                {/* Pagination */}
                <Stack direction="row" justify="center" mt={4}>
                    <Button
                        variant="outline"
                        size="sm"
                        onClick={() => paginate(currentPage > 1 ? currentPage - 1 : 1)}
                        isDisabled={currentPage === 1}
                    >
                        Previous
                    </Button>
                    {Array.from({ length: totalPages }, (_, index) => (
                        <Button
                            key={index}
                            variant={currentPage === index + 1 ? "solid" : "outline"}
                            onClick={() => paginate(index + 1)}
                            size="sm"
                        >
                            {index + 1}
                        </Button>
                    ))}
                    <Button
                        variant="outline"
                        size="sm"
                        onClick={() => paginate(currentPage < totalPages ? currentPage + 1 : totalPages)}
                        isDisabled={currentPage === totalPages}
                    >
                        Next
                    </Button>
                </Stack>
            </Card>
        </Box>
    );
}
