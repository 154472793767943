import React, { useState, useEffect } from 'react';
import {
    Box,
    Link,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Button,
    Stack,
    Input,
    useBreakpointValue
} from "@chakra-ui/react";
import Card from "components/card/Card";
import moment from 'moment';

export default function EmployeeReport() {
    const [employees, setEmployees] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [employeesPerPage] = useState(10);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        const userId = sessionStorage.getItem('userId');
        if (!userId) {
            window.location.href = '/login';
        } else {
            fetchEmployees(userId);
        }
    }, []);

    const fetchEmployees = async (userId) => {
        try {
            const response = await fetch(`https://api.cloudace.co.uk/get-employee?userId=${userId}`);
            if (response.ok) {
                const data = await response.json();
                setEmployees(data);
            } else {
                console.error('Failed to fetch employee data');
            }
        } catch (error) {
            console.error('Error fetching employee data:', error);
        }
    };

    const indexOfLastEmployee = currentPage * employeesPerPage;
    const indexOfFirstEmployee = indexOfLastEmployee - employeesPerPage;
    const currentEmployees = employees
        .filter(emp =>
            emp && (
                emp.eFname.toLowerCase().includes(searchTerm.toLowerCase()) ||
                emp.eLname.toLowerCase().includes(searchTerm.toLowerCase()) ||
                emp.eEmail.toLowerCase().includes(searchTerm.toLowerCase())
            )
        )
        .slice(indexOfFirstEmployee, indexOfLastEmployee);

    const totalPages = Math.ceil(
        employees.filter(emp =>
            emp && (
                emp.eFname.toLowerCase().includes(searchTerm.toLowerCase()) ||
                emp.eLname.toLowerCase().includes(searchTerm.toLowerCase()) ||
                emp.eEmail.toLowerCase().includes(searchTerm.toLowerCase())
            )
        ).length / employeesPerPage
    );

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const formatDate = (timestamp) => moment(timestamp).format('DD-MM-YYYY');

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        setCurrentPage(1); // Reset pagination to first page when searching
    };

    const exportToCSV = () => {
        const csvContent = [
            ['Name', 'Email', 'Role', 'Date of Joining', 'Wage / Salary', 'Contact No'],
            ...currentEmployees.map(emp => [
                `${emp.eFname} ${emp.eLname}`,
                emp.eEmail,
                emp.eRole,
                formatDate(emp.eDOJ),
                `£${emp.eBaiscPay}`,
                emp.eMobNo
            ])
        ];

        const csvRows = csvContent.map(row => row.join(',')).join('\n');
        const csvBlob = new Blob([csvRows], { type: 'text/csv;charset=utf-8' });
        const csvUrl = URL.createObjectURL(csvBlob);

        const tempLink = document.createElement('a');
        tempLink.href = csvUrl;
        tempLink.setAttribute('download', 'employees.csv');
        tempLink.click();

        URL.revokeObjectURL(csvUrl);
    };

    const inputWidth = useBreakpointValue({ base: '100%', md: 'auto' });

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
            <Link
                color='black'
                bg='inherit'
                borderRadius='inherit'
                fontWeight='bold'
                fontSize={{ base: "24px", md: "34px" }}
                _active={{
                    bg: 'inherit',
                    transform: 'none',
                    borderColor: 'transparent'
                }}
                _focus={{
                    boxShadow: 'none'
                }}>
                Employee Report
            </Link>
            <Card>
                <Stack spacing={4} direction="row" align="center">
                    <Button variant="brand" size="sm" onClick={exportToCSV}>
                        Export CSV
                    </Button>
                    <Input
                        type="text"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        placeholder="Search by name or email"
                        maxW={inputWidth}
                    />
                </Stack>
                <Box overflowX="auto">
                    <Table variant="striped" colorScheme="brand" mt={4} minW="100%">
                        <Thead>
                            <Tr>
                                <Th>SR</Th>
                                <Th>Name</Th>
                                <Th>Email</Th>
                                <Th>Role</Th>
                                <Th>Date of Joining</Th>
                                <Th>Wage / Salary</Th>
                                <Th>Contact No</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {currentEmployees.map((emp, index) => (
                                <Tr key={emp.id}>
                                    <Td>{indexOfFirstEmployee + index + 1}</Td>
                                    <Td>{`${emp.eFname} ${emp.eLname}`}</Td>
                                    <Td>{emp.eEmail}</Td>
                                    <Td>{emp.eRole}</Td>
                                    <Td>{formatDate(emp.eDOJ)}</Td>
                                    <Td>£{emp.eBaiscPay}</Td>
                                    <Td>{emp.eMobNo}</Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </Box>
                {/* Pagination */}
                <Stack direction="row" justify="center" mt={4}>
                    <Button
                        variant="outline"
                        colorScheme="gray"
                        disabled={currentPage === 1}
                        onClick={() => paginate(currentPage - 1)}
                    >
                        Previous
                    </Button>
                    {Array.from({ length: totalPages }, (_, index) => (
                        <Button
                            key={index + 1}
                            variant={currentPage === index + 1 ? "solid" : "outline"}
                            onClick={() => paginate(index + 1)}
                        >
                            {index + 1}
                        </Button>
                    ))}
                    <Button
                        variant="outline"
                        colorScheme="gray"
                        disabled={currentPage === totalPages}
                        onClick={() => paginate(currentPage + 1)}
                    >
                        Next
                    </Button>
                </Stack>
            </Card>
        </Box>
    );
}
