import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Badge,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Stack,
    FormControl,
    FormLabel,
    Select
} from "@chakra-ui/react";
import { NavLink } from 'react-router-dom';
import Card from "components/card/Card";
import { MdArrowDropDown } from "react-icons/md";
import moment from 'moment';

const yearOptions = () => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let year = currentYear; year >= currentYear - 10; year--) {
        years.push(year);
    }
    return years;
};

export default function Overview() {
    const [companies, setCompanies] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [companiesPerPage] = useState(10);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedMonth, setSelectedMonth] = useState('');
    const [selectedYear, setSelectedYear] = useState('');

    useEffect(() => {
        const userId = sessionStorage.getItem('userId');
        if (!userId) {
            window.location.href = '/login';
        } else {
            fetchCompanies(userId);
        }
    }, []);

    useEffect(() => {
        if (selectedMonth && selectedYear) {
            fetchCompanies(sessionStorage.getItem('userId'), selectedMonth, selectedYear);
        }
    }, [selectedMonth, selectedYear]);

    const fetchCompanies = async (userId, month, year) => {
        try {
            let url = `https://api.cloudace.co.uk/get-atds?userId=${userId}`;
            if (month && year) {
                url += `&month=${month}&year=${year}`;
            }
            const response = await fetch(url);
            if (response.ok) {
                const data = await response.json();
                setCompanies(data);
            } else {
                console.error('Failed to fetch company data');
            }
        } catch (error) {
            console.error('Error fetching company data:', error);
        }
    };

    const handleStatusChange = async (id, newStatus) => {
        try {
            const response = await fetch(`https://api.cloudace.co.uk/update-status/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ aStatus: newStatus })
            });

            if (response.ok) {
                const updatedCompanies = companies.map(company =>
                    company.id === id ? { ...company, aStatus: newStatus } : company
                );
                setCompanies(updatedCompanies);
            } else {
                console.error('Failed to update status');
            }
        } catch (error) {
            console.error('Error updating status:', error);
        }
    };

    const handleDelete = async (id) => {
        try {
            const response = await fetch(`https://api.cloudace.co.uk/delete-attendance/${id}`, {
                method: 'DELETE'
            });

            if (response.ok) {
                setCompanies(companies.filter(company => company.id !== id));
            } else {
                console.error('Failed to delete attendance record');
            }
        } catch (error) {
            console.error('Error deleting attendance record:', error);
        }
    };

    const indexOfLastCompany = currentPage * companiesPerPage;
    const indexOfFirstCompany = indexOfLastCompany - companiesPerPage;
    const filteredCompanies = companies.filter(company =>
        company.eFname.toLowerCase().includes(searchTerm.toLowerCase()) ||
        company.eLname.toLowerCase().includes(searchTerm.toLowerCase()) ||
        company.lName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        company.aDate.toLowerCase().includes(searchTerm.toLowerCase()) ||
        company.aInTime.toLowerCase().includes(searchTerm.toLowerCase()) ||
        company.aOutTime.toLowerCase().includes(searchTerm.toLowerCase()) ||
        company.aInTimeRemark.toLowerCase().includes(searchTerm.toLowerCase()) ||
        company.aDefaultBreak.toString().toLowerCase().includes(searchTerm.toLowerCase())
    );
    const currentCompanies = filteredCompanies.slice(indexOfFirstCompany, indexOfLastCompany);

    const totalPages = Math.ceil(filteredCompanies.length / companiesPerPage);

    const renderPageNumbers = () => {
        const pageNumbers = [];
        const maxPageDisplay = 5; // Maximum number of page numbers to display

        // Determine start and end of the range to display
        let startPage = Math.max(1, currentPage - Math.floor(maxPageDisplay / 2));
        let endPage = Math.min(totalPages, startPage + maxPageDisplay - 1);

        // Adjust startPage and endPage if needed to always show maxPageDisplay numbers
        if (endPage - startPage + 1 < maxPageDisplay) {
            startPage = Math.max(1, endPage - maxPageDisplay + 1);
        }

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(
                <Badge
                    key={i}
                    cursor="pointer"
                    colorScheme={currentPage === i ? "blue" : "gray"}
                    onClick={() => setCurrentPage(i)}
                >
                    {i}
                </Badge>
            );
        }

        return pageNumbers;
    };

    const goToNextPage = () => {
        setCurrentPage(currentPage + 1);
    };

    const goToPrevPage = () => {
        setCurrentPage(currentPage - 1);
    };

    const formatDate = (timestamp, isTime = false) => {
        if (isTime) {
            return moment(timestamp, 'HH:mm:ss').format('hh:mm A');
        } else {
            return moment(timestamp).format('DD-MM-YYYY');
        }
    };

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
            <Card>
                <Stack spacing={3} direction="row" align="center">
                    <Button variant="brand" size="md" width="100px">
                        <NavLink to='attendance-add' style={{ fontSize: "sm" }}>Add</NavLink>
                    </Button>
                    <FormControl width="150px">
                        <FormLabel fontSize="sm">Month:</FormLabel>
                        <Select
                            value={selectedMonth}
                            onChange={(e) => setSelectedMonth(e.target.value)}
                            size="sm"
                        >
                            <option value="">Select Any</option>
                            {moment.months().map((month, index) => (
                                <option key={index + 1} value={index + 1}>{month}</option>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl width="150px">
                        <FormLabel fontSize="sm">Year:</FormLabel>
                        <Select
                            value={selectedYear}
                            onChange={(e) => setSelectedYear(e.target.value)}
                            size="sm"
                        >
                            <option value="">Select Any</option>
                            {yearOptions().map(year => (
                                <option key={year} value={year}>{year}</option>
                            ))}
                        </Select>
                    </FormControl>
                    <Button size="sm" onClick={() => fetchCompanies(sessionStorage.getItem('userId'), selectedMonth, selectedYear)} width="80px">Filter</Button>
                </Stack>

                <br />

                <Box overflowX="auto"> {/* Ensure horizontal scrolling on smaller screens */}
                    <Table variant="striped" colorScheme="brand" size="sm">
                        <Thead>
                            <Tr>
                                <Th>SR</Th>
                                <Th>NAME</Th>
                                <Th>LOCATION</Th>
                                <Th>DATE</Th>
                                <Th>IN-TIME</Th>
                                <Th>OUT-TIME</Th>
                                <Th>Note</Th>
                                <Th>Status</Th>
                                <Th>Action</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {currentCompanies.map((company, index) => (
                                <Tr key={company.id}>
                                    <Td>{indexOfFirstCompany + index + 1}</Td>
                                    <Td>{`${company.eFname} ${company.eLname}`}</Td>
                                    <Td>{company.lName}</Td>
                                    <Td>{formatDate(company.aDate)}</Td>
                                    <Td>{formatDate(company.aInTime, true)}</Td>
                                    <Td>{formatDate(company.aOutTime, true)}</Td>
                                    <Td>{company.aInTimeRemark}</Td>
                                    <Td>
                                        {company.aStatus === 1 ? (
                                            <Badge colorScheme="green">Present</Badge>
                                        ) : (
                                            <Badge colorScheme="red">Absent</Badge>
                                        )}
                                    </Td>
                                    <Td>
                                        <Menu>
                                            <MenuButton as={Button} rightIcon={<MdArrowDropDown />} size="sm">
                                                Actions
                                            </MenuButton>
                                            <MenuList>
                                                <NavLink to={`attendance-edit/${company.id}`}>
                                                    <MenuItem>Edit</MenuItem>
                                                </NavLink>
                                                <MenuItem onClick={() => handleStatusChange(company.id, company.aStatus === 1 ? 2 : 1)}>
                                                    {company.aStatus === 1 ? 'Mark Absent' : 'Mark Present'}
                                                </MenuItem>
                                                <MenuItem onClick={() => handleDelete(company.id)}>Delete</MenuItem>
                                            </MenuList>
                                        </Menu>
                                    </Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </Box>
                <Stack direction="row" justify="center" mt={4}>
                    {currentPage > 1 && (
                        <Button onClick={goToPrevPage}>Previous</Button>
                    )}
                    {renderPageNumbers()}
                    {currentPage < totalPages && (
                        <Button onClick={goToNextPage}>Next</Button>
                    )}
                </Stack>
            </Card>
        </Box>
    );
}
