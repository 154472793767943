
import {
    Box,
    Link,
    Grid,
    Form,
    FormControl,
    FormLabel,
    Input,
    Select,
    Textarea,
    FormErrorMessage,
    FormHelperText,
    Button,
} from "@chakra-ui/react";
import Card from "components/card/Card"
import { useHistory } from 'react-router-dom';
import moment from 'moment'; // Import moment for date/time formatting

import React, { useState, useEffect } from 'react';
import { MdArrowDropDown, MdForkRight } from "react-icons/md";
import axios from 'axios';

export default function Overview() {
    const [formData, setFormData] = useState({
        rName: '',
        rBreakTIme: '',
        rReportsTo: ''
      });
    
      const [role, setLocations] = useState([]);
      const history = useHistory();
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          const userId = sessionStorage.getItem('userId');
          if (!userId) {
            console.error('User ID not found');
            return;
          }
    
          const formDataWithUserId = { ...formData, userId: userId };
          await saveFormDataToServer(formDataWithUserId);
    
          // Redirect to the role list page after successfully saving the data
          history.push('/admin/role'); // Using useNavigate from react-router-dom
        } catch (error) {
          console.error('Error saving data:', error);
        }
      };
    
      const saveFormDataToServer = async (data) => {
        const response = await fetch('https://api.cloudace.co.uk/add-role', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
        });
    
        if (!response.ok) {
          throw new Error('Failed to save data');
        }
      };
    
      useEffect(() => {
    
        const userId = sessionStorage.getItem('userId');
    
        if (userId === null) {
          window.location.href = '/login';
        }
    
        const fetchLocations = async () => {
          try {
            const response = await axios.get(`https://api.cloudace.co.uk/get-role?userId=${userId}`);
            setLocations(response.data);
          } catch (error) {
            console.error('Error fetching locations:', error);
          }
        };
    
    
        fetchLocations();
      }, []);
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        const updatedFormData = { ...formData, [name]: value };
    
        // If the field is "rReportsTo" and the value is an empty string, set it to null
        if (name === 'rReportsTo' && value === '') {
          updatedFormData[name] = null;
        }
    
        setFormData(updatedFormData);
      };
    return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
            <Card>
                <form onSubmit={handleSubmit}>

                    <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                        <FormControl id="employee">
                            <FormLabel>Role Name:</FormLabel>
                            <Input
                            type="text"
                            name="rName"
                            value={formData.rName}
                            onChange={handleChange}
                            required
                            className='form-control'
                          />
                        </FormControl>

                        <FormControl id="location">
                            <FormLabel>Default Break (In Hours)</FormLabel>
                            <Input
                            type="text"
                            name="rBreakTIme"
                            value={formData.rBreakTIme}
                            onChange={handleChange}
                            required
                            className='form-control'
                          />
                        </FormControl>

                   

                        <FormControl id="dateTo">
                            <FormLabel>Reports To:</FormLabel>
                            <Select
                            name="rReportsTo"
                            value={formData.rReportsTo}
                            onChange={handleChange}
                            className='form-control'
                          >
                            <option value="">Select Any</option>
                            {role.map(role => (
                              <option key={role.id} value={role.id}>{role.rName}</option>
                            ))}
                          </Select>
                        </FormControl>

                    </Grid>

                    <Button
                        mt={4}
                        colorScheme="brand"
                        type="submit"
                    >
                        Submit
                    </Button>
                </form>
            </Card>
        </Box>

    );
}
