
import {
    Box,
    Link,
    Grid,
    Form,
    FormControl,
    FormLabel,
    Radio,
    RadioGroup,
    Input,
    Select,
    HStack,
    Textarea,
    FormErrorMessage,
    FormHelperText,
    Button,
} from "@chakra-ui/react";
import Card from "components/card/Card"
import { useHistory } from 'react-router-dom';
import moment from 'moment'; // Import moment for date/time formatting

import React, { useState, useEffect } from 'react';
import { MdArrowDropDown, MdForkRight } from "react-icons/md";
import axios from 'axios';

export default function Overview() {
    const [formData, setFormData] = useState({
        eFname: '',
        eLname: '',
        eEmail: '',
        eDOB: '',
        eDOJ: '',
        eMobNo: '',
        eRole: '',
        ePassword: '',
        eBaiscPay: '',
        eLocation: ''
    });

    const [emailError, setEmailError] = useState('');
    const history = useHistory();
    const [role, setRole] = useState([]);
    const [gender, setGender] = useState([]);
    const [location, setLocations] = useState([]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const userId = sessionStorage.getItem('userId');
            if (!userId) {
                console.error('User ID not found');
                return;
            }
            const formDataWithUserId = { ...formData, userId: userId };
            await saveFormDataToServer(formDataWithUserId);
        } catch (error) {
            console.error('Error saving data:', error);
        }
    };

    const saveFormDataToServer = async (data) => {
        try {
            const response = await axios.post('https://api.cloudace.co.uk/add-employee', data);

            if (response.data.success) {
                // Employee added successfully
                history.push('/admin/employee');
            }
        } catch (error) {
            console.error('Error saving data:', error);
            if (error.response && error.response.status === 400 && error.response.data === 'Email already exists') {
                setEmailError('Email is already registered. Please enter a new email.');
            }
        }
    };

    useEffect(() => {
        const userId = sessionStorage.getItem('userId');
        if (!userId) {
            history.push('/auth/sign-in');
        }

        const fetchData = async () => {
            try {
                const [rolesResponse, locationsResponse] = await Promise.all([
                    axios.get(`https://api.cloudace.co.uk/get-role?userId=${userId}`),
                    axios.get(`https://api.cloudace.co.uk/get-location?userId=${userId}`)
                ]);
                setRole(rolesResponse.data);
                setLocations(locationsResponse.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };


        const fetchGender = async () => {
            try {

                const response = await fetch(`https://api.cloudace.co.uk/get-gender`);
                if (response.ok) {
                    const data = await response.json();
                    setGender(data);
                } else {
                    console.error('Failed to fetch location data');
                }
            } catch (error) {
                console.error('Error fetching location data:', error);
            }
        };

        fetchData();
        fetchGender();
    }, [history]);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
            <Card>
                <form onSubmit={handleSubmit}>
                    <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                        <FormControl id="firstName">
                            <FormLabel>First Name:</FormLabel>
                            <Input
                                type="text"
                                name="eFname"
                                value={formData.eFname}
                                onChange={handleChange}
                                required
                            />
                        </FormControl>
                        <FormControl id="lastName">
                            <FormLabel>Last Name:</FormLabel>
                            <Input
                                type="text"
                                name="eLname"
                                value={formData.eLname}
                                onChange={handleChange}
                                required
                            />
                        </FormControl>
                        <FormControl id="email">
                            <FormLabel>Email Address:</FormLabel>
                            <Input
                                type="email"
                                name="eEmail"
                                value={formData.eEmail}
                                onChange={handleChange}
                                required
                                className={emailError ? 'is-invalid' : ''}
                            />
                            {emailError && <div className="invalid-feedback">{emailError}</div>}
                        </FormControl>
                        <FormControl id="dob">
                            <FormLabel>Date Of Birth:</FormLabel>
                            <Input
                                type="date"
                                name="eDOB"
                                value={formData.eDOB}
                                onChange={handleChange}
                                required
                            />
                        </FormControl>
                        <FormControl id="doj">
                            <FormLabel>Date Of Join:</FormLabel>
                            <Input
                                type="date"
                                name="eDOJ"
                                value={formData.eDOJ}
                                onChange={handleChange}
                                required
                            />
                        </FormControl>
                        <FormControl id="doj">
                            <FormLabel>Select Gender:</FormLabel>
                            <Select
                                name="eGender"
                                value={formData.eGender}
                                onChange={handleChange}
                                required
                                className='form-control'
                            >   <option>Select Any</option>
                                {gender.map(gender => (

                                    <option key={gender.id} value={gender.id}>{gender.gName}</option>
                                ))}
                            </Select>

                        </FormControl>
                        <FormControl id="salary">
                            <FormLabel>Salary:</FormLabel>
                            <Input
                                type="number"
                                name="eBaiscPay"
                                value={formData.eBaiscPay}
                                onChange={handleChange}
                                required
                            />
                        </FormControl>
                        <FormControl id="contact">
                            <FormLabel>Contact Number:</FormLabel>
                            <Input
                                type="number"
                                name="eMobNo"
                                value={formData.eMobNo}
                                onChange={handleChange}
                                required
                            />
                        </FormControl>
                        <FormControl id="role">
                            <FormLabel>Role:</FormLabel>
                            <Select
                                name="eRole"
                                value={formData.eRole}
                                onChange={handleChange}
                                required
                                className='form-control'
                            >
                                <option>Select Any</option>
                                {role.map(role => (
                                    <option key={role.id} value={role.id}>{role.rName}</option>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl id="locations">
                            <FormLabel>Locations:</FormLabel>
                            <Select
                                name="eLocation"
                                value={formData.eLocation}
                                onChange={handleChange}
                                required
                                className='form-control'
                            >
                                <option>Select Any</option>
                                {location.map(location => (
                                    <option key={location.id} value={location.id}>{location.lName}</option>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl id="password">
                            <FormLabel>Password:</FormLabel>
                            <Input
                                type="password"
                                name="ePassword"
                                value={formData.ePassword}
                                onChange={handleChange}
                                required
                            />
                        </FormControl>
                    </Grid>
                    <Button mt={4} colorScheme="brand" type="submit">
                        Submit
                    </Button>
                </form>
            </Card>
        </Box>

    );
}
