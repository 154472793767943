import React, { useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import DefaultAuth from 'layouts/auth/Default';
import illustration from 'assets/img/auth/auth.png';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { RiEyeCloseLine } from 'react-icons/ri';

function SignIn() {
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const history = useHistory();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('https://api.cloudace.co.uk/reset-password-company', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email })
            });

            if (response.ok) {
                const data = await response.json();
                console.log(data.message); // Log success message
                history.push('/auth/sign-in'); // Redirect to login page after sending reset link
            } else {
                const errorData = await response.json();
                setError(errorData.error); // Display error message to the user
            }
        } catch (error) {
            console.error('Login error:', error);
        }
    };


  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  const textColor = useColorModeValue('navy.700', 'white');
  const textColorSecondary = 'gray.400';
  const brandStars = useColorModeValue('brand.500', 'brand.400');

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: '100%', md: 'max-content' }}
        w='100%'
        mx={{ base: 'auto', lg: '0px' }}
        me='auto'
        h='100%'
        alignItems='start'
        justifyContent='center'
        mb={{ base: '30px', md: '60px' }}
        px={{ base: '25px', md: '0px' }}
        mt={{ base: '40px', md: '14vh' }}
        flexDirection='column'
      >
        <Box me='auto'>
          <Heading color={textColor} fontSize='36px' mb='10px'>
            Forgot Password!
          </Heading>
          <Text
            mb='36px'
            ms='4px'
            color={textColorSecondary}
            fontWeight='400'
            fontSize='md'
          >
            Enter your email to get the password reset link!
          </Text>
        </Box>
        <Flex
          direction='column'
          w={{ base: '100%', md: '420px' }}
          maxW='100%'
          background='transparent'
          borderRadius='15px'
          mx={{ base: 'auto', lg: 'unset' }}
          me='auto'
          mb={{ base: '20px', md: 'auto' }}
        >
          <form onSubmit={handleSubmit}>
            <FormControl>
              <FormLabel htmlFor='email' color={textColor} mb='8px'>
                Email<span style={{ color: brandStars }}>*</span>
              </FormLabel>
              <Input
                id='email'
                type='email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </FormControl>
            
            {error && (
              <Text color='red.500' mt='2' fontSize='sm'>
                {error}
              </Text>
            )}
            <Button
              type='submit'
              variant='brand'
              mt='24px'
              w='100%'
              h='50px'
              fontSize='sm'
              fontWeight='500'
            >
              Send Reset Link
            </Button>
          </form>
          <Flex justifyContent='space-between' alignItems='center' mt='24px'>
         
     
            <NavLink to='/auth/sign-in'>
              <Text color={brandStars} fontSize='sm' fontWeight='500'>
                Sign In
              </Text>
            </NavLink>
          </Flex>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default SignIn;
