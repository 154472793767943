import React, { useState, useEffect } from 'react';
import {
    Box, Grid, Tab, TabList, Tabs, FormControl, FormLabel, Select, Input, Button, Table, Badge,
    Thead,
    RadioGroup,
    Radio,
    Tbody,
    HStack,
    Tfoot,
    Link,
    Tr,
    Th,
    Td,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    TableCaption,
    useDisclosure,
    Stack,
} from "@chakra-ui/react";
import { MdDashboard, MdSettings, MdPerson, MdDownload, MdDelete, MdClear } from "react-icons/md";
import axios from 'axios';
import Card from "components/card/Card"
import { useParams } from 'react-router-dom';
import moment from 'moment';

const menuItems = [
    { name: "Company Profile", icon: MdDashboard },
    { name: "Personal Information", icon: MdSettings },
    { name: "Documents", icon: MdPerson },
    { name: "My Leave", icon: MdPerson }, // Adding "Leave" menu item
];


const Overview = ({ employeeId }) => {

    const [documents, setDocuments] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedItem, setSelectedItem] = useState(menuItems[0]);
    const [leavesPerPage] = useState(10);
    const [searchTerm, setSearchTerm] = useState(''); // Default selected item

    const handleItemClick = (item) => {
        setSelectedItem(item);
    };
    const [documentUrl, setDocumentUrl] = useState(null);
    const [edFileName, setEdFileName] = useState(null);
    const [role, setRole] = useState([]);
    const [location, setLocations] = useState([]);
    const [gender, setGender] = useState([]);
    const [leaves, setLeves] = useState([]);
    const [isEditing, setIsEditing] = useState(false); // State to determine if it's in edit mode
    const [activeTab, setActiveTab] = useState('details'); // State to manage active tab
    const { id } = useParams(); // Extract the employee ID from the URL parameters
    const [employee, setEmployee] = useState({
        eFname: '',
        eLname: '',
        eDOB: '',
        eGender: '',
        eMobNo: '',
        eEmrgContPerson: '',
        eEmrgContNo: '',
        eEmgRelation: '',
        eAddressOne: '',
        eAddressTwo: '',
        eCity: '',
        eCountry: '',
        eZipCode: '',
        eEmail: '',
        ePassword: ''
    });

    // Function to fetch document based on employee ID
    const fetchDocument = async (employeeId) => {
        try {
            // Make a GET request to retrieve the documents based on the employee ID
            const response = await axios.get(`https://api.cloudace.co.uk/get-documents?employeeId=${employeeId}`);
            // If documents exist, set them in the state
            if (response.status === 200 && response.data) {
                setDocuments(response.data);
            } else {
                console.error('Failed to fetch documents');
            }
        } catch (error) {
            console.error('Error fetching documents:', error);
        }
    };

    const fetchDocuments = async (employeeId) => {
        try {
            const response = await axios.get(`https://api.cloudace.co.uk/get-documents?employeeId=${employeeId}`);
            if (response.status === 200 && response.data) {
                setDocuments(response.data);
            } else {
                console.error('Failed to fetch documents');
            }
        } catch (error) {
            console.error('Error fetching documents:', error);
        }
    };

    useEffect(() => {
        if (employeeId) {
            fetchDocuments(employeeId);
        }
    }, [employeeId]);

    useEffect(() => {
        const fetchEmployee = async () => {
            try {
                const response = await fetch(`https://api.cloudace.co.uk/employee/${id}`);
                if (response.ok) {
                    const data = await response.json();
                    setEmployee(data);
                    // Call function to fetch document based on employee ID
                    fetchDocument(data.id);
                } else {
                    console.error('Failed to fetch employee data');
                }
            } catch (error) {
                console.error('Error fetching employee data:', error);
            }
        };


        const fetchRoles = async () => {
            try {
                const userId = sessionStorage.getItem('userId');
                const response = await fetch(`https://api.cloudace.co.uk/get-role?userId=${userId}`);
                if (response.ok) {
                    const data = await response.json();
                    setRole(data);
                } else {
                    console.error('Failed to fetch company data');
                }
            } catch (error) {
                console.error('Error fetching company data:', error);
            }
        };

        const fetchLocations = async () => {
            try {
                const userId = sessionStorage.getItem('userId');
                const response = await fetch(`https://api.cloudace.co.uk/get-location?userId=${userId}`);
                if (response.ok) {
                    const data = await response.json();
                    setLocations(data);
                } else {
                    console.error('Failed to fetch location data');
                }
            } catch (error) {
                console.error('Error fetching location data:', error);
            }
        };

        const fetchGender = async () => {
            try {

                const response = await fetch(`https://api.cloudace.co.uk/get-gender`);
                if (response.ok) {
                    const data = await response.json();
                    setGender(data);
                } else {
                    console.error('Failed to fetch location data');
                }
            } catch (error) {
                console.error('Error fetching location data:', error);
            }
        };

        const fetchLeaves = async () => {
            try {
                const response = await fetch(`https://api.cloudace.co.uk/get-leaves/${id}`);
                if (response.ok) {
                    const data = await response.json();
                    if (Array.isArray(data)) {
                        setLeves(data); // Set state with array of leaves
                    } else if (typeof data === 'object' && Object.keys(data).length > 0) {
                        // If data is a single object, convert it into an array with one item
                        setLeves([data]); // Set state with an array containing the single leave object
                    } else {
                        console.error('Invalid leaves data:', data);
                    }
                } else {
                    console.error('Failed to fetch leaves data');
                }
            } catch (error) {
                console.error('Error fetching leaves data:', error);
            }
        };



        fetchEmployee();
        fetchLeaves();
        fetchRoles();
        fetchLocations();
        fetchGender();
        // Pass 'id' instead of 'data.id'
    }, [id]);

    // Function to handle editing




    // Function to handle tab change
    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    const [showModal, setShowModal] = useState(false);
    const [title, setTitle] = useState('');
    const [file, setFile] = useState(null);

    const handleAddDocumentClick = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setTitle('');
        setFile(null);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        if (name === 'edFileName') {
            setTitle(value);
        } else if (name === 'edFile') {
            setFile(event.target.files[0]); // Store the selected file
        }
    };

    const handleFormSubmit = async (event) => {
        event.preventDefault();
        // Form data
        const formData = new FormData();
        formData.append('edFileName', title); // Append the title
        formData.append('edFile', file); // Append the file

        // Retrieve userId from session storage
        const userId = sessionStorage.getItem('userId');
        if (!userId) {
            console.error('User ID not found in session storage');
            return;
        }

        // Add edEmpId to formData
        formData.append('edEmpId', employee.id); // Assuming `employee.id` is accessible in the component

        formData.append('userId', userId); // Append the userId to the form data

        try {
            // Send data to the backend API for adding document
            const response = await axios.post('https://api.cloudace.co.uk/add-document', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log('Document added successfully:', response.data);
            // Close modal after successful addition
            handleCloseModal();
            // Reload the page
            window.location.reload();
        } catch (error) {
            console.error('Error adding document:', error);
        }
    };

    const handleEmployeeEdit = async () => {
        try {
            const response = await axios.put(`https://api.cloudace.co.uk/edit-employee/${id}`, employee);
            console.log('Employee details updated successfully:', response.data);
            window.location.reload(); // Reload the page after successful update
        } catch (error) {
            console.error('Error updating employee details:', error);
        }
    };


    const handleChange = (e) => {
        const { name, value } = e.target;
        setEmployee(prevEmployee => ({
            ...prevEmployee,
            [name]: value
        }));
    };

    const handleEdit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`https://api.cloudace.co.uk/update-employee/${id}`, {
                ...employee,
                eDOB: employee.eDOB ? employee.eDOB.substring(0, 10) : '' // Extracting date in YYYY-MM-DD format
            });
            console.log(response.data); // Handle success response
            if (response.status === 200) {
                window.location.reload(); // Reload the page if the update was successful
            }
        } catch (error) {
            console.error('Error updating employee:', error);
            // Handle error
        }
    };



    const handleDeleteDocument = async (documentId) => {
        try {
            // Send DELETE request to server to delete the document
            await axios.delete(`https://api.cloudace.co.uk/delete-document/${documentId}`);

            // Document deleted successfully, reload the page to reflect changes
            window.location.reload();

        } catch (error) {
            console.error('Error deleting document:', error);
            // Handle error (e.g., show error message)
        }
    };

    const { isOpen, onOpen, onClose } = useDisclosure()

    const indexOfLastLeave = currentPage * leavesPerPage;
    const indexOfFirstLeave = indexOfLastLeave - leavesPerPage;

    const filteredLeave = leaves.filter(item =>
        (item.eFname && item.eFname.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (item.eLname && item.eLname.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (item.lType && item.lType.toLowerCase().includes(searchTerm.toLowerCase()))
    );

    const currentLeave = filteredLeave.slice(indexOfFirstLeave, indexOfLastLeave);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const renderStatusBadge = (lStatus) => {
        let statusText;
        let badgeColor;

        switch (lStatus) {
            case 0:
                statusText = 'Action Pending';
                badgeColor = 'gray';
                break;
            case 1:
                statusText = 'Accepted';
                badgeColor = 'green';
                break;
            case 2:
                statusText = 'Rejected';
                badgeColor = 'red';
                break;
            default:
                statusText = '';
                badgeColor = '';
        }

        return <Badge variant="solid" colorScheme={badgeColor}>{statusText}</Badge>;
    };

    const formatDate = (timestamp) => {
        const date = new Date(timestamp);
        return moment(date).format('DD-MM-YYYY');
    };

    return (
        <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
            <Grid
                templateColumns={{
                    base: '1fr',    // Full width on small screens
                    sm: '1fr',      // Full width on medium screens
                    md: '1fr 1fr',  // Two columns on large screens
                    lg: '1fr 1fr 1fr', // Three columns on extra-large screens
                    xl: '20% 80% '   // 30% and 70% columns on extra-large screens
                }}
                gap={{ base: '20px', xl: '20px' }}>
                <Box>
                    <Card>
                        <Tabs isLazy orientation="vertical" size="md">
                            <TabList isFitted>
                                {menuItems.map((item, index) => (
                                    <Tab
                                        key={index}
                                        onClick={() => handleItemClick(item)}
                                        _selected={{ bgColor: "gray.200", fontWeight: "bold" }}
                                        _hover={{ bgColor: "gray.100" }}
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="flex-start"
                                        p={3}
                                        borderRadius="md"
                                        cursor="pointer"
                                        bgColor={item === selectedItem ? "gray.200" : "transparent"}
                                    >
                                        {/* Icon */}
                                        <Box as={item.icon} size={20} />

                                        {/* Text */}
                                        <Box ml={3} fontWeight={item === selectedItem ? "bold" : "normal"}>
                                            {item.name}
                                        </Box>
                                    </Tab>
                                ))}
                            </TabList>
                        </Tabs>
                    </Card>
                </Box>
                <Box p={4}>
                    <Card>
                        {selectedItem.name === "Company Profile" && (
                            <form onSubmit={handleEmployeeEdit}>
                                <Grid templateColumns={{ base: "1fr", md: "repeat(3, 1fr)" }} gap={4}>
                                    <FormControl mb={4}>
                                        <FormLabel>Role:</FormLabel>
                                        <Select
                                            name="eRole"
                                            value={employee.eRole}
                                            onChange={handleChange}
                                            required
                                            className='form-control'
                                        >
                                            <option>Select Any</option>
                                            {role.map(role => (
                                                <option key={role.id} value={role.id}>{role.rName}</option>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <FormControl mb={4}>
                                        <FormLabel>Location:</FormLabel>
                                        <Select
                                            name="eLocation"
                                            value={employee.eLocation}
                                            onChange={handleChange}
                                            required
                                            className='form-control'
                                        >
                                            <option>Select Any</option>
                                            {location.map(loc => (
                                                <option key={loc.id} value={loc.id}>{loc.lName}</option>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <FormControl mb={4}>
                                        <FormLabel>Basic Pay:</FormLabel>
                                        <Input
                                            type="text"
                                            name="eBaiscPay"
                                            className='form-control'
                                            value={employee.eBaiscPay}
                                            onChange={handleChange}
                                        />
                                    </FormControl>
                                    <Button type="submit" colorScheme="blue">Save Changes</Button>
                                </Grid>
                            </form>
                        )}

                        {selectedItem.name === "Personal Information" && (
                            <div>
                                <form onSubmit={handleEdit}>
                                    <h3>Basic Information</h3>
                                    <Grid templateColumns={{ base: "1fr", md: "repeat(3, 1fr)" }} gap={4}>
                                        {/* Input fields for personal information */}
                                        <FormControl mb={4}>
                                            <FormLabel>First Name:</FormLabel>
                                            <Input
                                                type="text"
                                                name="eFname"
                                                className='form-control'
                                                required
                                                value={employee.eFname || ''} // Populate with employee data
                                                onChange={handleChange} // Add onChange if needed
                                            />
                                        </FormControl>

                                        <FormControl mb={4}>
                                            <FormLabel>Last Name:</FormLabel>
                                            <Input
                                                type="text"
                                                name="eLname"
                                                className='form-control'
                                                required
                                                value={employee.eLname || ''}
                                                onChange={handleChange}
                                            />
                                        </FormControl>

                                        <FormControl mb={4}>
                                            <FormLabel>Date Of Birth:</FormLabel>
                                            <Input
                                                type="date"
                                                name="eDOB"
                                                className='form-control'
                                                required
                                                value={employee.eDOB ? employee.eDOB.substring(0, 10) : ''} // Displaying date in YYYY-MM-DD format
                                                onChange={handleChange}
                                            />
                                        </FormControl>

                                        <FormControl mb={4}>
                                            <FormLabel>Date Of Join:</FormLabel>
                                            <Input
                                                type="date"
                                                name="eDOJ"
                                                className='form-control'
                                                required
                                                value={employee.eDOJ ? employee.eDOJ.substring(0, 10) : ''} // Displaying date in YYYY-MM-DD format
                                                onChange={handleChange}
                                            />
                                        </FormControl>

                                        <FormControl id="doj">
                                            <FormLabel>Select Gender:</FormLabel>
                                            <RadioGroup defaultValue="Gender">


                                                <Select
                                                    name="eGender"
                                                    value={employee.eGender}
                                                    onChange={handleChange}
                                                    required
                                                    className='form-control'
                                                >   <option>Select Any</option>
                                                    {gender.map(gender => (

                                                        <option key={gender.id} value={gender.id}>{gender.gName}</option>
                                                    ))}
                                                </Select>

                                            </RadioGroup>


                                        </FormControl>

                                        <FormControl mb={4}>
                                            <FormLabel>Mobile No:</FormLabel>
                                            <Input
                                                type="number"
                                                name="eMobNo"
                                                className='form-control'
                                                required
                                                value={employee.eMobNo || ''}
                                                onChange={handleChange}
                                            />
                                        </FormControl>

                                        <FormControl mb={4}>
                                            <FormLabel>Password:</FormLabel>
                                            <Input
                                                type="text"
                                                name="ePassword"
                                                className='form-control'
                                                required
                                                value={employee.ePassword || ''}
                                                onChange={handleChange}
                                            />
                                        </FormControl>
                                        <FormControl mb={4}>
                                        </FormControl>
                                        <FormControl mb={4}>
                                        </FormControl>
                                        <h3>Contact Details</h3>
                                        <FormControl mb={4}>
                                        </FormControl>
                                        <FormControl mb={4}>
                                        </FormControl>


                                        <FormControl mb={4}>
                                            <FormLabel>Address Line 1:</FormLabel>
                                            <Input
                                                type="text"
                                                name="eAddressOne"
                                                className='form-control'
                                                required
                                                value={employee.eAddressOne || ''}
                                                onChange={handleChange}
                                            />
                                        </FormControl>

                                        <FormControl mb={4}>
                                            <FormLabel>Address Line 2:</FormLabel>
                                            <Input
                                                type="text"
                                                name="eAddressTwo"
                                                className='form-control'
                                                required
                                                value={employee.eAddressTwo || ''}
                                                onChange={handleChange}
                                            />
                                        </FormControl>

                                        <FormControl mb={4}>
                                            <FormLabel>City:</FormLabel>
                                            <Input
                                                type="text"
                                                name="eCity"
                                                className='form-control'
                                                required
                                                value={employee.eCity || ''}
                                                onChange={handleChange}
                                            />
                                        </FormControl>

                                        <FormControl mb={4}>
                                            <FormLabel>Country:</FormLabel>
                                            <Input
                                                type="text"
                                                name="eCountry"
                                                className='form-control'
                                                required
                                                value={employee.eCountry || ''}
                                                onChange={handleChange}
                                            />
                                        </FormControl>


                                        <FormControl mb={4}>
                                            <FormLabel>Postcode:</FormLabel>
                                            <Input
                                                type="text"
                                                name="eZipCode"
                                                className='form-control'
                                                required
                                                value={employee.eZipCode || ''}
                                                onChange={handleChange}
                                            />
                                        </FormControl>

                                        <FormControl mb={4}>
                                            <FormLabel>Email:</FormLabel>
                                            <Input
                                                type="email"
                                                name="eEmail"
                                                className='form-control'
                                                required
                                                value={employee.eEmail || ''}
                                                onChange={handleChange}
                                            />
                                        </FormControl>


                                        <h3>Emergency Information</h3>
                                        <FormControl mb={4}>
                                        </FormControl>
                                        <FormControl mb={4}>
                                        </FormControl>

                                        <FormControl mb={4}>
                                            <FormLabel>Emergency Contact Number:</FormLabel>
                                            <Input
                                                type="text"
                                                name="eEmrgContNo"
                                                className='form-control'
                                                required
                                                value={employee.eEmrgContNo || ''}
                                                onChange={handleChange}
                                            />
                                        </FormControl>

                                        <FormControl mb={4}>
                                            <FormLabel>Relationship To Employee:</FormLabel>
                                            <Input
                                                type="text"
                                                name="eEmgRelation"
                                                className='form-control'
                                                required
                                                value={employee.eEmgRelation || ''}
                                                onChange={handleChange}
                                            />
                                        </FormControl>
                                        <FormControl mb={4}>
                                        </FormControl>
                                    </Grid>
                                    <Button type="submit" colorScheme="blue">Save Changes</Button>
                                </form>
                            </div>
                        )}

                        {selectedItem.name === "Documents" && (
                            <div>
                                <Button onClick={onOpen} colorScheme="brand">Add Documents</Button>
                                <Modal isOpen={isOpen} onClose={onClose}>
                                    <ModalOverlay />
                                    <ModalContent>
                                        <ModalHeader>Add Your Documents</ModalHeader>
                                        <ModalCloseButton />
                                        <ModalBody>
                                            <form onSubmit={handleFormSubmit}>
                                                {/* Hidden input for employee ID */}
                                                <Input type="hidden" name="edEmpId" value={employee.id} />
                                                <FormControl mb={4}>
                                                    <FormLabel htmlFor="title" className="form-label">Title</FormLabel>
                                                    <Input type="text" id="edFileName" name="edFileName" value={title} onChange={handleInputChange} required />
                                                </FormControl>
                                                <FormControl mb={4}>
                                                    <FormLabel htmlFor="file" className="form-label">File</FormLabel>
                                                    <Input type="file" id="edFile" name="edFile" onChange={handleInputChange} required />
                                                </FormControl>
                                                <div className="modal-footer">
                                                    <Button type="submit" colorScheme="blue">Add</Button>
                                                </div>
                                            </form>
                                        </ModalBody>
                                    </ModalContent>
                                </Modal>
                                <Table variant="striped" colorScheme="brand">
                                    <Thead>
                                        <Tr>
                                            <Th>Sr</Th>
                                            <Th>Document Name</Th>
                                            <Th>Added On</Th>
                                            <Th>Action</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {documents.map((document, index) => (
                                            <Tr key={document.id}>
                                                <Td>{index + 1}</Td>
                                                <Td>{document.edFileName}</Td>
                                                <Td>{formatDate(document.edCreateTime)}</Td>
                                                <Td>
                                                    <Link
                                                        colorScheme="red"
                                                        href={`https://api.cloudace.co.uk/documents/${String(document.edFile)}`}
                                                        target='_blank'
                                                        download={document.edFileName}
                                                        className='btn btn-sm text-success'
                                                        style={{
                                                            display: 'inline-block',
                                                            marginRight: '10px',
                                                            textDecoration: 'none',
                                                            color: 'blue'
                                                        }}
                                                    >
                                                        <strong> <MdDownload /> </strong>
                                                    </Link>
                                                    <Button
                                                        colorScheme="red"
                                                        onClick={() => handleDeleteDocument(document.id)}
                                                    >
                                                        <strong> <MdDelete /> </strong>
                                                    </Button>
                                                </Td>
                                            </Tr>
                                        ))}
                                    </Tbody>
                                </Table>
                            </div>
                        )}

                        {selectedItem.name === "My Leave" && (
                            <div>
                                <Table variant="striped" colorScheme="brand">
                                    <Thead>
                                        <Tr>
                                            <Th>SR</Th>
                                            <Th>EMPLOYEE NAME</Th>
                                            <Th>LEAVE TYPE</Th>
                                            <Th>FROM DATE</Th>
                                            <Th>TO DATE</Th>
                                            <Th>REASON</Th>
                                            <Th>STATUS</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {currentLeave.map((leaves, index) => (
                                            <Tr key={leaves.id}>
                                                <Td>{indexOfFirstLeave + index + 1}</Td>
                                                <Td>{`${leaves.eFname} ${leaves.eLname}`}</Td>
                                                <Td>{leaves.lType}</Td>
                                                <Td>{formatDate(leaves.lFromDate)}</Td>
                                                <Td>{formatDate(leaves.lToDate)}</Td>
                                                <Td>{leaves.lReason}</Td>
                                                <Td>{renderStatusBadge(leaves.lStatus)}</Td>
                                            </Tr>
                                        ))}
                                    </Tbody>
                                </Table>
                                <Stack direction="row" justify="center" mt={4}>
                                    {Array.from({ length: Math.ceil(filteredLeave.length / leavesPerPage) }, (_, index) => (
                                        <Badge
                                            key={index}
                                            cursor="pointer"
                                            colorScheme={currentPage === index + 1 ? "blue" : "gray"}
                                            onClick={() => paginate(index + 1)}
                                        >
                                            {index + 1}
                                        </Badge>
                                    ))}
                                </Stack>
                            </div>
                        )}
                    </Card>
                </Box>

            </Grid>
        </Box>
    );
};

export default Overview;
