import React, { useState, useEffect } from 'react';
import { Box, Grid, FormControl, FormLabel, Input, Select, Button } from "@chakra-ui/react";
import Card from "components/card/Card";
import { useHistory, useParams } from 'react-router-dom';
import axios from 'axios';
import { Textarea } from '@chakra-ui/react/dist/chakra-ui-react.cjs';
import { IoNavigate } from 'react-icons/io5';

export default function Overview() {
    const [formData, setFormData] = useState({
        lName: '',
        lAddress: ''
    });
    const { id } = useParams(); // Extracting the location ID from the URL

    const history = useHistory();

    useEffect(() => {
        const userId = sessionStorage.getItem('userId');

        if (userId === null) {
            window.location.href = '/login';
        }

        // Fetch location data to edit
        const fetchLocation = async () => {
            try {
                const response = await axios.get(`https://api.cloudace.co.uk/get-location/${id}`);
                if (response.data) {
                    setFormData(response.data);
                } else {
                    console.error('Failed to fetch location data');
                }
            } catch (error) {
                console.error('Error fetching location data:', error);
            }
        };

        fetchLocation();
    }, [id]); // Fetch data whenever the ID changes


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await updateLocation(formData);
            history.push('/admin/location');
        } catch (error) {
            console.error('Error updating data:', error);
        }
    };

    const updateLocation = async (data) => {
        try {
            await axios.put(`https://api.cloudace.co.uk/edit-location/${id}`, data);
        } catch (error) {
            console.error('Error updating location:', error);
            throw new Error('Failed to update location');
        }
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };


    return (
        <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
            <Card>
                <form onSubmit={handleSubmit}>
                    <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                        <FormControl id="roleName">
                            <FormLabel>Location Name</FormLabel>
                            <Input
                                type="text"
                                name="lName"
                                value={formData.lName}
                                onChange={handleChange}
                                required
                                className='form-control'
                            />
                        </FormControl>
                        <FormControl id="breakTime">
                            <FormLabel>Address</FormLabel>
                            <Textarea
                                name="lAddress"
                                value={formData.lAddress}
                                onChange={handleChange}
                                required
                                className='form-control'
                            />
                        </FormControl>

                    </Grid>
                    <Button mt={4} colorScheme="brand" type="submit">
                        Submit
                    </Button>
                </form>
            </Card>
        </Box>
    );
}
