import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdBarChart,
  MdPerson,
  MdHome,
  MdLock,
  MdOutlineShoppingCart,
  MdCalendarViewWeek,
  MdNote,
  MdNoteAdd,
  MdListAlt,
  MdLocationPin,
  MdPerson4,
  MdPending,
  MdOutbox,
} from "react-icons/md";

// Admin Imports
import MainDashboard from "views/admin/default";
import Profile from "views/admin/profile";
import DataTables from "views/admin/dataTables";
import Calendar from "views/admin/Calendar";
import Attendance from "views/admin/Attendance";
import AttendanceAdd from "views/admin/Attendance/AttendanceAdd";
import AttendanceEdit from "views/admin/Attendance/AttendanceEdit";
import Role from "views/admin/Employee/Role";
import RoleAdd from "views/admin/Employee/RoleAdd";
import RoleHierarchy from "views/admin/Employee/RoleHierarchy";
import RoleEdit from "views/admin/Employee/RoleEdit";
import Location from "views/admin/Employee/Location";
import LocationAdd from "views/admin/Employee/LocationAdd";
import LocationEdit from "views/admin/Employee/LocationEdit";

import NotificationList from "views/admin/Notification/";

import Employee from "views/admin/Employee/Employee";
import EmployeeAdd from "views/admin/Employee/EmployeeAdd";
import EmployeeView from "views/admin/Employee/EmolyeeView";

import CompEmployeeReport from "views/admin/Report/EmployeeReport";
import AgendaReport from "views/admin/Report/AgendaReport";
import EmpLeaveReport from "views/admin/Report/LeaveReport";

import Leave from "views/admin/Employee/Leave";
import LeaveAdd from "views/admin/Employee/LeaveAdd";


import SignInCentered from "views/auth/signIn";
import ForgotPassword from "views/auth/signIn/ForgotPassword";
import ResetPassword from "views/auth/signIn/ResetPassword";
import { IoMdGitMerge } from "react-icons/io";

const routes = [
  {
    name: "Dashboard",
    layout: "/admin",
    path: "/default",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: MainDashboard,
  },
  {
    name: "Calendar",
    layout: "/admin",
    icon: <Icon as={MdCalendarViewWeek} width="20px" height="20px" color="inherit" />,
    path: "/calendar",
    component: Calendar,
  },
  {
    name: "Attendance",
    layout: "/admin",
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: "/attendance",
    component: Attendance,
  },
  {
    name: "Role",
    layout: "/admin",
    icon: <Icon as={MdNote} width="20px" height="20px" color="inherit" />,
    path: "/role",
    component: Role,
  },
  {
    name: "Role Hierarchy",
    layout: "/admin",
    icon: <Icon as={IoMdGitMerge} width="20px" height="20px" color="inherit" />,
    path: "/role-hierarchy",
    component: RoleHierarchy,
  },
  {
    name: "Location",
    layout: "/admin",
    icon: <Icon as={MdLocationPin} width="20px" height="20px" color="inherit" />,
    path: "/location",
    component: Location,
  },
  {
    name: "Employees",
    layout: "/admin",
    icon: <Icon as={MdPerson4} width="20px" height="20px" color="inherit" />,
    path: "/employee",
    component: Employee,
  },
  {
    name: "Leave",
    layout: "/admin",
    icon: <Icon as={MdPending} width="20px" height="20px" color="inherit" />,
    path: "/leave",
    component: Leave,
  },
  {
    name: "Attendance Report",
    layout: "/admin",
    icon: <Icon as={MdOutbox} width="20px" height="20px" color="inherit" />,
    path: "/agenda-report",
    component: AgendaReport,
  },
  {
    name: "Employee Report",
    layout: "/admin",
    icon: <Icon as={MdOutbox} width="20px" height="20px" color="inherit" />,
    path: "/employee-report",
    component: CompEmployeeReport,
  },
  {
    name: "Leave Report",
    layout: "/admin",
    icon: <Icon as={MdOutbox} width="20px" height="20px" color="inherit" />,
    path: "/leave-report",
    component: EmpLeaveReport,
  },
  {
    name: "My Profile",
    layout: "/admin",
    path: "/profile/:id",
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: Profile,
    hidden: true,
  },
  {
    name: "Log out",
    layout: "/auth",
    path: "/sign-in",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: SignInCentered,
    hidden: true,
  },
  {
    name: "Forgot Password",
    layout: "/auth",
    path: "/forgot-password",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: ForgotPassword,
    hidden: true,
  },
  {
    name: "Reset Password",
    layout: "/auth",
    path: "/reset-password/:token",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: ResetPassword,
    hidden: true,
  },
  {
    name: "Attendance Add",
    layout: "/admin",
    path: "/attendance-add",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: AttendanceAdd,
    hidden: true, // Add a 'hidden' flag to hide this route from the sidebar
  },
  {
    name: "Attendance Edit",
    layout: "/admin",
    path: "/attendance-edit/:id",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: AttendanceEdit,
    hidden: true, // Add a 'hidden' flag to hide this route from the sidebar
  },
  {
    name: "Role Add",
    layout: "/admin",
    icon: <Icon as={MdNote} width="20px" height="20px" color="inherit" />,
    path: "/role-add",
    component: RoleAdd,
    hidden: true,
  },
  {
    name: "Role Edit",
    layout: "/admin",
    icon: <Icon as={MdNote} width="20px" height="20px" color="inherit" />,
    path: "/role-edit/:id",
    component: RoleEdit,
    hidden: true,
  },
  {
    name: "Location Add",
    layout: "/admin",
    icon: <Icon as={MdNote} width="20px" height="20px" color="inherit" />,
    path: "/location-add",
    component: LocationAdd,
    hidden: true,
  },
  {
    name: "leave Add",
    layout: "/admin",
    icon: <Icon as={MdNote} width="20px" height="20px" color="inherit" />,
    path: "/leave-add",
    component: LeaveAdd,
    hidden: true,
  },
  {
    name: "location Edit",
    layout: "/admin",
    icon: <Icon as={MdNote} width="20px" height="20px" color="inherit" />,
    path: "/location-edit/:id",
    component: LocationEdit,
    hidden: true,
  },

  {
    name: "Employee Add",
    layout: "/admin",
    icon: <Icon as={MdNote} width="20px" height="20px" color="inherit" />,
    path: "/employee-add",
    component: EmployeeAdd,
    hidden: true,
  },
  {
    name: "Employee Edit",
    layout: "/admin",
    icon: <Icon as={MdNote} width="20px" height="20px" color="inherit" />,
    path: "/employee-view/:id",
    component: EmployeeView,
    hidden: true,
  },
  {
    name: "Notification List",
    layout: "/admin",
    icon: <Icon as={MdNote} width="20px" height="20px" color="inherit" />,
    path: "/notification",
    component: NotificationList,
    hidden: true,
  },
];

export default routes;
