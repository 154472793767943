import React, { useState, useEffect } from 'react';
import { Box, Link, Table, Thead, Tbody, Tr, Th, Td, Button, Stack, Input, Menu, MenuItem, MenuList, MenuButton } from "@chakra-ui/react";
import Card from "components/card/Card";
import { NavLink } from 'react-router-dom';

export default function Overview() {
    const [locations, setLocations] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [locationsPerPage] = useState(10);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        const userId = sessionStorage.getItem('userId');

        if (!userId) {
            window.location.href = '/login';
        } else {
            fetchLocations(userId);
        }
    }, []);

    const fetchLocations = async (userId) => {
        try {
            const response = await fetch(`https://api.cloudace.co.uk/get-location?userId=${userId}`);
            if (response.ok) {
                const data = await response.json();
                setLocations(data);
            } else {
                console.error('Failed to fetch location data');
            }
        } catch (error) {
            console.error('Error fetching location data:', error);
        }
    };

    const indexOfLastLocation = currentPage * locationsPerPage;
    const indexOfFirstLocation = indexOfLastLocation - locationsPerPage;

    const filteredLocations = locations.filter(location =>
        location.lName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const currentLocations = filteredLocations.slice(indexOfFirstLocation, indexOfLastLocation);

    const totalPages = Math.ceil(filteredLocations.length / locationsPerPage);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        setCurrentPage(1); // Reset pagination to first page when searching
    };

    const handleDelete = async (id) => {
        try {
            await fetch(`https://api.cloudace.co.uk/delete-location/${id}`, {
                method: 'DELETE'
            });
            setLocations(locations.filter(location => location.id !== id));
        } catch (error) {
            console.error('Error deleting location:', error);
        }
    };

    const goToPreviousPage = () => {
        setCurrentPage(currentPage - 1);
    };

    const goToNextPage = () => {
        setCurrentPage(currentPage + 1);
    };

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
            <Link
                color='black'
                href='#'
                bg='inherit'
                borderRadius='inherit'
                fontWeight='bold'
                fontSize='34px'
                _active={{
                    bg: 'inherit',
                    transform: 'none',
                    borderColor: 'transparent'
                }}
                _focus={{
                    boxShadow: 'none'
                }}>
                Location List
            </Link>
            <Card>
                <Stack spacing={4} direction="row" align="center">
                    <Button variant="brand" size="sm">
                        <NavLink to='location-add' fontSize="sm">Add Location</NavLink>
                    </Button>
                    <Input
                        type="text"
                        placeholder="Search by Location Name"
                        value={searchTerm}
                        onChange={handleSearchChange}
                    />
                </Stack>
                {/* Main Fields */}
                <Box overflowX="auto">
                    <Table variant="striped" colorScheme="brand">
                        <Thead>
                            <Tr>
                                <Th>SR</Th>
                                <Th>Location Name</Th>
                                <Th>Address</Th>
                                <Th>Action</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {currentLocations.map((location, index) => (
                                <Tr key={location.id}>
                                    <Td>{indexOfFirstLocation + index + 1}</Td>
                                    <Td>{location.lName}</Td>
                                    <Td>{location.lAddress}</Td>
                                    <Td>
                                        <Menu>
                                            <MenuButton as={Button}>
                                                Actions
                                            </MenuButton>
                                            <MenuList>
                                                <NavLink to={`location-edit/${location.id}`}><MenuItem>Edit</MenuItem></NavLink>
                                                <MenuItem onClick={() => handleDelete(location.id)}>Delete</MenuItem>
                                            </MenuList>
                                        </Menu>
                                    </Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </Box>
                {/* Pagination */}
                <Stack direction="row" justify="center" mt={4}>
                    <Button
                        variant="outline"
                        colorScheme="gray"
                        disabled={currentPage === 1}
                        onClick={goToPreviousPage}
                    >
                        Previous
                    </Button>
                    {Array.from({ length: totalPages }, (_, index) => (
                        <Button
                            key={index + 1}
                            variant={currentPage === index + 1 ? "solid" : "outline"}
                            onClick={() => paginate(index + 1)}
                        >
                            {index + 1}
                        </Button>
                    ))}
                    <Button
                        variant="outline"
                        colorScheme="gray"
                        disabled={currentPage === totalPages}
                        onClick={goToNextPage}
                    >
                        Next
                    </Button>
                </Stack>
            </Card>
        </Box>
    );
}
