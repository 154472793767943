import { Box, Grid } from "@chakra-ui/react";
import React, { useState, useEffect } from 'react';
import Tree from 'react-d3-tree';
import Card from "components/card/Card";

export default function Overview() {
    const [roles, setRoles] = useState([]);
    const [companyName, setCompanyName] = useState('');
  
    useEffect(() => {
      const userId = sessionStorage.getItem('userId');
      if (userId === null) {
        window.location.href = '/login';
      }
  
      const fetchCompanyData = async (userId) => {
        try {
          // Fetch company name using the userId
          const response = await fetch(`https://api.cloudace.co.uk/fetch-compname?userId=${userId}`);
          if (response.ok) {
            const data = await response.json();
            setCompanyName(data.cCompName);
          } else {
            console.error('Failed to fetch company name');
          }
        } catch (error) {
          console.error('Error fetching company name:', error);
        }
      };
  
      const fetchRoleHierarchy = async (userId) => {
        try {
          // Fetch role hierarchy using the userId
          const response = await fetch(`https://api.cloudace.co.uk/get-role-hierarchy?userId=${userId}`);
          if (response.ok) {
            const data = await response.json();
            setRoles(data);
          } else {
            console.error('Failed to fetch role hierarchy');
          }
        } catch (error) {
          console.error('Error fetching role hierarchy:', error);
        }
      };
  
      fetchCompanyData(userId);
      fetchRoleHierarchy(userId);
    }, []);
  
    const buildTreeData = (roles) => {
      const root = { name: companyName, children: [] };
  
      roles.forEach((role) => {
        const roleNode = { name: role.roleName, children: [] };
        if (role.employees && role.employees.length > 0) {
          role.employees.forEach((employee) => {
            const fullName = `${employee.eFname}\n${employee.eLname}`; // Separate names with a newline
            roleNode.children.push({ name: fullName });
          });
        }
        root.children.push(roleNode);
      });
  
      return root;
    };
  
    const treeData = buildTreeData(roles);

    return (
        <Box pt={{ base: "250px", md: "80px", xl: "80px" }}>
            <Grid
                templateColumns="1fr"
                templateRows="1fr 1fr"
                gap={{ base: "20px", xl: "20px" }}
            >
                {/* Full-width Card */}
                <Card
                    gridArea="1 / 1 / 2 / 2"
                    minHeight="600px"
                >
                  <Tree
                          data={treeData}
                          orientation="vertical"
                          translate={{ x: 900, y: 150 }} // Adjust these values for centering
                          separation={{ siblings: 1.5, nonSiblings: 3.5 }} // Optional node spacing
                        />
                </Card>

                {/* Two Cards in a Row */}
             
            </Grid>
        </Box>
    );
}
