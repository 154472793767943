
import {
    Box,
    Link,
    Grid,
    Form,
    FormControl,
    FormLabel,
    Input,
    Select,
    Textarea,
    FormErrorMessage,
    FormHelperText,
    Button,
} from "@chakra-ui/react";
import Card from "components/card/Card"
import { useHistory } from 'react-router-dom';
import moment from 'moment'; // Import moment for date/time formatting

import React, { useState, useEffect } from 'react';
import { MdArrowDropDown, MdForkRight } from "react-icons/md";
import axios from 'axios';

export default function Overview() {
    const [formData, setFormData] = useState({
        rName: '',
        lAddress: ''
    });

    const history = useHistory();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const userId = sessionStorage.getItem('userId');
            if (!userId) {
                console.error('User ID not found');
                return;
            }

            const formDataWithUserId = { ...formData, userId: userId };
            await saveFormDataToServer(formDataWithUserId);
            history.push('/admin/location');
        } catch (error) {
            console.error('Error saving data:', error);
        }
    };


    const saveFormDataToServer = async (data) => {
        const response = await fetch('https://api.cloudace.co.uk/add-location', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });

        if (!response.ok) {
            throw new Error('Failed to save data');
        }
    };

    useEffect(() => {
        const userId = sessionStorage.getItem('userId');

        if (userId === null) {
            window.location.href = '/login';
        }
    }, []);


    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
            <Card>
                <form onSubmit={handleSubmit}>

                    <Grid templateColumns="repeat(1, 1fr)" gap={4}>
                    

                        <FormControl id="location">
                            <FormLabel>Location Name</FormLabel>
                            <Input
                              type="text"
                              name="lName"
                              value={formData.lName}
                              onChange={handleChange}
                              required
                              className='form-control'
                            />
                        </FormControl>



                        <FormControl id="address">
                            <FormLabel>Address</FormLabel>
                            <Textarea
                              name="lAddress"
                              value={formData.lAddress}
                              onChange={handleChange}
                              required
                              className='form-control'
                            />
                        </FormControl>

                    </Grid>

                    <Button
                        mt={4}
                        colorScheme="brand"
                        type="submit"
                    >
                        Submit
                    </Button>
                </form>
            </Card>
        </Box>

    );
}
