import React from 'react';
import ReactDOM from 'react-dom';
import 'assets/css/App.css';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import AuthLayout from 'layouts/auth';
import AdminLayout from 'layouts/admin';
import RtlLayout from 'layouts/rtl';
import { ChakraProvider } from '@chakra-ui/react';
import theme from 'theme/theme';
import { ThemeEditorProvider } from '@hypertheme-editor/chakra-ui';
import AttendanceAdd from 'views/admin/Attendance/AttendanceAdd'; // Import the AttendanceAdd component

const routes = [
  { path: '/auth', component: AuthLayout },
  { path: '/admin', component: AdminLayout },
  { path: '/rtl', component: RtlLayout },
  { path: '/admin/attendance-add', component: AttendanceAdd }, // New route for AttendanceAdd component
];

ReactDOM.render(
  <ChakraProvider theme={theme}>
    <React.StrictMode>
      <ThemeEditorProvider>
        <HashRouter>
          <Switch>
            {/* Map over the routes array and create Route components dynamically */}
            {routes.map((route, index) => (
              <Route key={index} path={route.path} component={route.component} />
            ))}
            {/* Redirect from '/' to '/admin' by default */}
            <Redirect exact from='/' to='/admin' />
          </Switch>
        </HashRouter>
      </ThemeEditorProvider>
    </React.StrictMode>
  </ChakraProvider>,
  document.getElementById('root')
);
