import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    FormControl,
    FormLabel,
    Radio,
    RadioGroup,
    Input,
    Select,
    HStack,
    Textarea
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import axios from 'axios';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

const localizer = momentLocalizer(moment);

const Overview = () => {
    const [events, setEvents] = useState([]);
    const [showEventModal, setShowEventModal] = useState(false);
    const [showReminderModal, setShowReminderModal] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [reminderText, setReminderText] = useState('');
    const [employee, setLocations] = useState([]);
    const [leavetype, setLeaveType] = useState([]);
    const history = useHistory();

    const [formData, setFormData] = useState({
        rEmployeeId: '',
        rDate: '',
        rDescription: ''
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const userId = sessionStorage.getItem('userId');
            if (!userId) {
                console.error('User ID not found');
                return;
            }

            const formDataWithUserId = { ...formData, userId: userId };
            await saveFormDataToServer(formDataWithUserId);
            history.push('/admin/calendar');
        } catch (error) {
            console.error('Error saving data:', error);
        }
    };

    const saveFormDataToServer = async (data) => {
        const response = await fetch('https://api.cloudace.co.uk/add-reminder', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        window.location.reload();

        if (!response.ok) {
            throw new Error('Failed to save data');
        }
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    useEffect(() => {
        const fetchEvents = async () => {
            try {
                const userId = sessionStorage.getItem('userId');
                const response = await axios.get(`https://api.cloudace.co.uk/get-events?userId=${userId}`);

                const formattedEvents = response.data.map(event => {
                    const startTime = moment(`${event.aDate} ${event.aInTime}`, 'YYYY-MM-DD HH:mm:ss').toDate();
                    const endTime = moment(`${event.aDate} ${event.aOutTime}`, 'YYYY-MM-DD HH:mm:ss').toDate();

                    return {
                        id: event.id,
                        title: `${event.eFname} ${event.eLname}`,
                        start: startTime,
                        end: endTime,
                        aStatus: event.aStatus,
                        aInTimeRemark: event.aInTimeRemark,
                        aLeaveStatus: event.aLeaveStatus,
                    };
                });

                setEvents(formattedEvents);
            } catch (error) {
                console.error('Error fetching events:', error);
            }
        };

        fetchEvents();
    }, []);

    const handleEventClick = event => {
        setSelectedEvent(event);
        setShowEventModal(true);
    };

    const handleCloseEventModal = () => {
        setShowEventModal(false);
    };

    const handleAddReminder = () => {
        setShowReminderModal(true);
    };

    const handleReminderSubmit = () => {
        console.log('Reminder Text:', reminderText);
        setReminderText('');
        setShowReminderModal(false);
    };

    const eventStyleGetter = event => {
        let style = {
            backgroundColor: '',
            color: 'black',
        };

        if (event.aLeaveStatus === 3) {
            style.backgroundColor = '#ffcccc';
            style.color = 'black';
        } else if (event.aStatus === 2) {
            style.backgroundColor = 'red';
            style.color = 'white';
        } else {
            const colors = ['#116361', '#197d6e', '#2ca786', '#4dc69f', '#6fd2b9'];
            const colorIndex = event.id % colors.length;
            style.backgroundColor = colors[colorIndex];
            style.color = 'white';
        }

        return {
            style: style,
        };
    };

    useEffect(() => {
        const userId = sessionStorage.getItem('userId');

        if (userId === null) {
            window.location.href = '/login';
        }

        const fetchLocations = async () => {
            try {
                const response = await axios.get(`https://api.cloudace.co.uk/get-employee?userId=${userId}`);
                setLocations(response.data);
            } catch (error) {
                console.error('Error fetching locations:', error);
            }
        };

        const fetchType = async () => {
            try {
                const response = await axios.get('https://api.cloudace.co.uk/get-leavetype');
                setLeaveType(response.data);
            } catch (error) {
                console.error('Error fetching leavetype:', error);
            }
        };

        fetchLocations();
        fetchType();
    }, []);

    const eventTimeRangeFormat = ({ start, end }) => {
        const startTime = moment(start).format('h:mm A');
        const endTime = moment(end).format('h:mm A');
        return `${startTime} - ${endTime}`;
    };

    const handleDrillDown = (date, view) => {
        if (view === 'day') {
            history.push('/admin/attendance');
        }
    };

    return (
        <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
            <Card>
                <Button width={{ md: '190px', xl: '190px' }} onClick={handleAddReminder}>
                    Add Reminder
                </Button>
                <Calendar
                    localizer={localizer}
                    events={events}
                    startAccessor="start"
                    endAccessor="end"
                    style={{ height: 500, margin: '50px' }}
                    eventPropGetter={eventStyleGetter}
                    onSelectEvent={handleEventClick}
                    views={['month', 'week', 'day']}
                    formats={{
                        timeGutterFormat: 'h:mm A',
                        eventTimeRangeFormat: eventTimeRangeFormat,
                    }}
                    onDrillDown={handleDrillDown}
                />

                <Modal isOpen={showEventModal} onClose={handleCloseEventModal}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Event Details</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            {selectedEvent && (
                                <div>
                                    <p>Title: {selectedEvent.title}</p>
                                    <p>Time: {eventTimeRangeFormat(selectedEvent)}</p>
                                    {selectedEvent.aLeaveStatus === 3 && (
                                        <p style={{ color: 'red' }}>Employee is on Leave</p>
                                    )}
                                    {selectedEvent.aStatus === 2 && (
                                        <p style={{ color: 'red' }}>Employee is Absent</p>
                                    )}
                                    <h5>Agenda: {selectedEvent.aInTimeRemark}</h5>
                                </div>
                            )}
                        </ModalBody>
                        <ModalFooter>
                        </ModalFooter>
                    </ModalContent>
                </Modal>

                <Modal isOpen={showReminderModal} onClose={() => setShowReminderModal(false)}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Add Reminder</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <form onSubmit={handleSubmit}>
                                <FormControl id="employee">
                                    <FormLabel>Select Employee:</FormLabel>
                                    <Select
                                        name="rEmployeeId"
                                        value={formData.rEmployeeId}
                                        onChange={handleChange}
                                        required
                                        className='form-control'
                                    >
                                        <option>Select Any</option>
                                        {employee.map(employee => (
                                            <option key={employee.id} value={employee.id}>{employee.eFname} {employee.eMname} {employee.eLname}</option>
                                        ))}
                                    </Select>
                                </FormControl>

                                <FormControl id="date">
                                    <FormLabel>Select Date:</FormLabel>
                                    <Input
                                        type="date"
                                        name="rDate"
                                        value={formData.rDate}
                                        onChange={handleChange}
                                        required
                                        className='form-control'
                                    />
                                </FormControl>

                                <FormControl id="description">
                                    <FormLabel>Description:</FormLabel>
                                    <Textarea
                                        name="rDescription"
                                        value={formData.rDescription}
                                        onChange={handleChange}
                                        required
                                        className='form-control'
                                    />
                                </FormControl>
                                <Button
                                    mt={4}
                                    colorScheme="brand"
                                    type="submit"
                                >
                                    Submit
                                </Button>
                            </form>
                        </ModalBody>
                        <ModalFooter>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </Card>
        </Box >
    );
};

export default Overview;
